export class InitialDialog {
  public removeInitialModalFromDom() {
    let initialDialog = document.getElementById("initial-modal");
    if (initialDialog) {
      initialDialog.remove();
    }
  }
  //create and render modal which contains iframe
  public renderInitialModal(interval?: any) {
    const listenAndCloseInterval = (event: any) => {
      if (event.data.status === "SIGNATOR_DRAWER_CLOSED") {
        this.removeInitialModalFromDom();
        if (interval) interval();
      }
    };

    window.addEventListener("message", listenAndCloseInterval, false);

    //create stylesheet
    let styleSheet = document.createElement("style");

    styleSheet.innerHTML = `
      .initial-modal-content {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 0;
        max-width: 500px;
        width: 100%;
        box-shadow: none !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .initial-modal-content-body {
        font-weight: bold;
        text-align: center;
        font-family: 'OpenSans';
        align-items: center;
        font-size: larger;
        display: flex;
        justify-content: center;
        height: 200px;
      }

      .spinner-size {
        width: 7rem;
        height: 7rem;
      }
      `;

    //append CSS for modal
    document.head.appendChild(styleSheet);

    //create the background element for modal
    const modal = document.createElement("div");
    modal.id = "initial-modal";

    //create modal content div element
    const modalContent = document.createElement("div");
    modalContent.classList.add("initial-modal-content");

    //create modal body element
    const modalContentBody = document.createElement("div");
    modalContentBody.classList.add("initial-modal-content-body");
    const modalContentLoader = document.createElement("div");
    modalContentLoader.classList.add("spinner-grow");
    modalContentLoader.classList.add("text-info");
    modalContentLoader.classList.add("spinner-size");

    let parentElementToAppend = document.getElementById(
      "signator_drawer_container"
    );

    //render the modal element with child iframe on DOM
    modal.appendChild(modalContent);
    modalContent.appendChild(modalContentBody);
    modalContentBody.appendChild(modalContentLoader);
    try {
      parentElementToAppend!.appendChild(modal);
    } catch (e) {
      console.log(e);
    }
  }
}
