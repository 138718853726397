import { ErrorModalService } from "../ErrorHandling/ErrorModalService";
import { VIDEO_IDENT_IFRAME_URL } from "../../appsettings";

export class VideoIdentDialogService {
  private readonly sigToken: string;
  private readonly jurisdiction: string;
  private readonly sigLevel: string;
  private readonly mobileNumber: string;
  private readonly device: string;
  private readonly prepaid: string;
  private readonly identUrl?: string;
  private readonly isMismatch?: string;

  constructor(
    sigToken: string,
    jurisdiction: string,
    sigLevel: string,
    mobileNumber: string,
    device: string,
    prepaid: string,
    identUrl?: string,
    isMismatch?: string
  ) {
    this.sigToken = sigToken;
    this.jurisdiction = jurisdiction;
    this.sigLevel = sigLevel;
    this.mobileNumber = mobileNumber;
    this.device = device;
    this.prepaid = prepaid;
    this.identUrl = identUrl;
    this.isMismatch = isMismatch;
  }

  public showVideoIdentModal() {
    //create stylesheet
    let styleSheet = document.createElement("style");

    styleSheet.innerHTML = `
   .signing-modal-content-video-ident {
        width: 100%;
        margin-top: 20px;
        height: 100%
    }
    
    .signator-video-ident-app-modal{
        height: 100%;
        width: 100%;
    }
   `;

    //append CSS for modal
    document.head.appendChild(styleSheet);

    let getDrawerElement = document.getElementById("signator-secondary-drawer");

    //create modal content div element
    const modalContent = document.createElement("div");
    modalContent.classList.add("signing-modal-content-video-ident");

    //create the inner iframe element
    const iframe = document.createElement("iframe");
    iframe.id = "signator-video-ident-app-modal";
    iframe.classList.add("signator-video-ident-app-modal");
    iframe.sandbox.add("allow-forms");
    iframe.sandbox.add("allow-same-origin");
    iframe.sandbox.add("allow-scripts");
    iframe.sandbox.add("allow-popups");
    iframe.sandbox.add("allow-top-navigation");
    iframe.allow = "camera *; microphone *";
    iframe.src = this.identUrl
      ? VIDEO_IDENT_IFRAME_URL +
        `?jurisdiction=${this.jurisdiction}&sig_level=${
          this.sigLevel
        }&phone_number=${this.mobileNumber}&prepaid=${
          this.prepaid
        }&signature_token=${this.sigToken ?? ""}
    &targetIdentUrl=${this.identUrl}&isMismatch=${this.isMismatch ?? "false"}\``
      : VIDEO_IDENT_IFRAME_URL +
        `?jurisdiction=${this.jurisdiction}&sig_level=${
          this.sigLevel
        }&phone_number=${this.mobileNumber}&prepaid=${
          this.prepaid
        }&signature_token=${this.sigToken ?? ""}&isMismatch=${
          this.isMismatch ?? "false"
        }
    `;

    modalContent.appendChild(iframe);

    if (getDrawerElement) {
      getDrawerElement.appendChild(modalContent);
    } else {
      setTimeout(() => {
        let getDrawerElementAfterTimeout = document.getElementById(
          "signator-secondary-drawer"
        );
        getDrawerElementAfterTimeout?.appendChild(modalContent);
      }, 500);
    }

    function listenForVideoIdentFinish(event: any) {
      /*if (event.data === "FAILED") {
        setTimeout(() => {
            //TODO: CHECK IF THIS IS BEING USED AT ALL
        }, 2000);
      }
      if (event.data === "FINISHED") {
        setTimeout(() => {
            //TODO: CHECK IF THIS IS BEING USED AT ALL
        }, 2000);
      }
      if (event.data === "STOPPED") {
        setTimeout(() => {
            //TODO: CHECK IF THIS IS BEING USED AT ALL
        }, 2000);
      }*/
      if (event.data.status === "FAILED") {
        setTimeout(() => {
          new ErrorModalService(24).showErrorModal();
        }, 2000);
      }
      if (event.data.status === "ERROR") {
        setTimeout(() => {
          new ErrorModalService(24).showErrorModal();
        }, 2000);
      }
      if (event.data.status === "SUCCESS_CLOSE_PRESSED") {
        window.postMessage(
          { type: "SIGNATOR_VIDEO_IDENT_SUCCESS_FINISHED" },
          "*"
        );
      }
    }

    window.addEventListener("message", listenForVideoIdentFinish, false);
  }
}
