import React from "react";
import { SignatureProvider } from "../components/SigningComponent/SigningComponent";

export enum SignaturePosition {
  PHASE_ONE = "phase_one",
  PHASE_TWO = "phase_two",
}

interface ISignatureParametersContext {
  predefinedSignatureOptions: boolean;
  directSignOption: boolean;
  signatureToken: string | undefined | null;
  pdfFile: Uint8Array | undefined;
  pdfFileBase64: string | undefined;
  phoneNumber: string | undefined | null;
  jurisdiction: string | undefined | null;
  signatureLevel: string | undefined | null;
  prepaid: string | undefined | null;
  targetIdentUrl: string | undefined | null;
  storageId: string | undefined | null;
  signatureProvider: SignatureProvider;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  signatureImageBase64Data: string | undefined;
  pageForSignaturePosition: number | undefined;
  documentHasBeenSigned: boolean;
  signaturePosition: SignaturePosition | undefined | null;
}

const SignatureParametersContext =
  React.createContext<ISignatureParametersContext>({
    predefinedSignatureOptions: false,
    directSignOption: true,
    signatureToken: undefined,
    pdfFile: undefined,
    pdfFileBase64: undefined,
    phoneNumber: undefined,
    jurisdiction: undefined,
    signatureLevel: undefined,
    prepaid: undefined,
    targetIdentUrl: undefined,
    storageId: undefined,
    signatureProvider: SignatureProvider.SwisscomAis,
    firstName: undefined,
    lastName: undefined,
    signatureImageBase64Data: undefined,
    pageForSignaturePosition: 0,
    documentHasBeenSigned: false,
    signaturePosition: null,
  });

export default SignatureParametersContext;
