import {
  Jurisdiction,
  SignatureLevel,
  SignatureProvider,
} from "./components/SigningComponent/SigningComponent";

enum AppEnv {
  ON_PREM = "ON_PREM",
  PRODUCTION = "PRODUCTION",
  DEMO = "DEMO",
  DEV = "DEV",
}

export const PRODUCTION_BUILD = false;

export const ENABLED_LANGUAGES = ["en", "fr", "it", "de"];

export const LANGUAGES = [
  {
    code: "en",
    languageName: "EN",
    country_code: "gb",
  },
  {
    code: "fr",
    languageName: "FR",
    country_code: "fr",
  },
  {
    code: "it",
    languageName: "IT",
    country_code: "it",
  },
  {
    code: "de",
    languageName: "DE",
    country_code: "de",
  },
];

export const EnabledSignatureProviders = {
  SwisscomEnabled: true,
  CertiliaEnabled: true,
  CertiliaRddEnabled: true,
};

export const PaymentAndIdentCheckEnabled: boolean = false;

let appEnvironment: AppEnv;

appEnvironment = AppEnv.ON_PREM;

export const SigningOptionsPredefined: boolean = false;
export const defaultPredefinedStandard = Jurisdiction.ZERTES;
export const defaultPredefinedProvider = SignatureProvider.SwisscomAis;
export const defaultPredefinedLevel = SignatureLevel.QES;

export let BASE_URL = "";
export let REDIRECT_URL = "";
export let VIDEO_IDENT_IFRAME_URL = "";

if ((appEnvironment as AppEnv) === AppEnv.DEV) {
  BASE_URL = "https://esign-lite-dev.signator.eu";
  REDIRECT_URL = "https://esign-lite-dev.signator.eu";
  VIDEO_IDENT_IFRAME_URL =
    "https://mobile.signator.hr/signator_video_ident_app/";
} else if ((appEnvironment as AppEnv) === AppEnv.DEMO) {
  BASE_URL = "https://esign-lite-demo.signator.eu";
  REDIRECT_URL = "https://esign-lite-demo.signator.eu";
  VIDEO_IDENT_IFRAME_URL =
    "https://mobile.signator.hr/signator_video_ident_app/";
} else if ((appEnvironment as AppEnv) === AppEnv.PRODUCTION) {
  BASE_URL = "https://esign-lite.signator.eu";
  REDIRECT_URL = "https://esign-lite.signator.eu";
  VIDEO_IDENT_IFRAME_URL =
    "https://mobile.signator.hr/signator_video_ident_app/";
} else if ((appEnvironment as AppEnv) === AppEnv.ON_PREM) {
  BASE_URL = window.location.origin;
  REDIRECT_URL = window.location.origin;
  VIDEO_IDENT_IFRAME_URL =
    "https://mobile.signator.hr/signator_video_ident_app/";
}

export const enableTooltipComponent = false;

export const PADDLE_VENDOR_ID_PRODUCTION = "141255";
export const PADDLE_VENDOR_ID_TEST = "5049";

export const PADDLE_PACKAGE_ONE_ID_PRODUCTION = "784394";
export const PADDLE_PACKAGE_ONE_ID_TEST = "32227";
export const PADDLE_PACKAGE_ONE_TEXT_PRODUCTION = "5 credits";
export const PADDLE_PACKAGE_ONE_TEXT_TEST = "10 credits";
export const PADDLE_PACKAGE_ONE_PRICE_PRODUCTION = " 10€";
export const PADDLE_PACKAGE_ONE_PRICE_TEST = " 10€";

export const PADDLE_PACKAGE_TWO_ID_PRODUCTION = "784395";
export const PADDLE_PACKAGE_TWO_ID_TEST = "32228";
export const PADDLE_PACKAGE_TWO_TEXT_PRODUCTION = "25 credits";
export const PADDLE_PACKAGE_TWO_TEXT_TEST = "100 credits";
export const PADDLE_PACKAGE_TWO_PRICE_PRODUCTION = " 45€";
export const PADDLE_PACKAGE_TWO_PRICE_TEST = " 100€";

export const PADDLE_PACKAGE_THREE_ID_PRODUCTION = "784397";
export const PADDLE_PACKAGE_THREE_ID_TEST = "32229";
export const PADDLE_PACKAGE_THREE_TEXT_PRODUCTION = "25 credits";
export const PADDLE_PACKAGE_THREE_TEXT_TEST = "1000 credits";
export const PADDLE_PACKAGE_THREE_PRICE_PRODUCTION = " 160€";
export const PADDLE_PACKAGE_THREE_PRICE_TEST = " 1000€";
