import LanguageButton from "../LanguageButton/LanguageButton";
import VizibitLogo from "../../images/vizibit.png";
import styles from "./Toolbar.module.css";
import PoweredByLogo from "../../images/PVB_3.png";
import SettingsButtonMenu from "../SettingsButtonMenu/SettingsButtonMenu";
import TipNavigationTooltip from "../TipsNavigationTooltip/TipsNavigationTooltip";

export const Toolbar = (props: any) => {
  return (
    <nav className={styles.Toolbar}>
      <div className={styles.LogoContainer}>
        <img
          className={styles.ToolbarLogo}
          src={PoweredByLogo}
          alt="Vizibit logo"
        />
      </div>
      <div style={{ display: "flex" }}>
        <div className={styles.ToolbarRightMenu}>
          <div className={styles.ToolbarMenuLanguage}>
            {props.showlanguage && <LanguageButton />}
          </div>
        </div>
        {/*        <div className={styles.ToolbarRightMenu}>
          <div className={styles.ToolbarMenuLanguage}>
            <SettingsButtonMenu backgroundColor={"#f5f5f5"} />
          </div>
        </div>*/}
      </div>
    </nav>
  );
};
