import {
  Jurisdiction,
  SignatureLevel,
  SignatureProvider,
} from "../components/SigningComponent/SigningComponent";

export interface IRequestModel {
  txtSignatureProvider: string | SignatureProvider | undefined;
  txtSignatureLevel: string | SignatureLevel | undefined;
  txtMobitel: string | undefined;
  txtSignatureStandard: string | Jurisdiction | undefined;
  txtName: string | undefined;
  txtCompanyName: string | undefined;
  txtDepartment: string | undefined;
  txtPosition: string | undefined;
  txtLogo: string | undefined;
  txtSigImageProfileName: string | undefined;
  txtSignatureImagePicture: string | undefined;
  txtAcceptTC: boolean | undefined;
}

export type FormModel = IRequestModel;

export const DEFAULT_SIGNING_MODEL: FormModel = {
  txtSignatureProvider: undefined,
  txtSignatureLevel: undefined,
  txtMobitel: undefined,
  txtSignatureStandard: undefined,
  txtName: undefined,
  txtCompanyName: undefined,
  txtDepartment: undefined,
  txtPosition: undefined,
  txtLogo: undefined,
  txtSigImageProfileName: undefined,
  txtSignatureImagePicture: undefined,
  txtAcceptTC: undefined,
};
