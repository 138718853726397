import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import i18next from "i18next";

const RejectButton = (props: any) => {
  //state variables

  const [openSignButtonDropdown, setOpenSignButtonDropdown] = useState({
    open: false,
    selectedIndex: 0,
  });

  //refs
  const signButtonDropdownAnchorRef = useRef<HTMLDivElement>(null);

  //handlers
  const handleClick = () => {
    props.showRejectHandler();
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={signButtonDropdownAnchorRef}
        aria-label="split button"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Button
          onClick={handleClick}
          style={{
            backgroundColor: "#db0000",
            borderColor: "white",
            color: "white",
            fontSize: "16px",
            marginLeft: "20px",
            marginRight: "5px",
            borderRadius: "12px",
            height: "40px",
            width: props.isMobile ? "40px" : "unset",
            minWidth: props.isMobile ? "40px" : "100px",
            fontWeight: "bold",
            fontFamily: "OpenSans",
            textTransform: "none",
          }}
          disabled={props.isSigAdded}
        >
          {props.isMobile ? <HighlightOffIcon /> : i18next.t("reject_button")}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default RejectButton;
