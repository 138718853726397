import React, { useContext, useEffect, useState } from "react";
import styles from "../SigningWizard.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Drawer, Spin } from "antd";
import { Fade } from "react-awesome-reveal";
import { useFormState } from "react-final-form";
import { FormModel } from "../../../services/signingModel";
import PdfSignerComponent, {
  Jurisdiction,
  SignatureImage,
  SignatureLevel,
  SignatureOptions,
  SignatureProvider,
  SignatureProviderData,
  SwisscomRequestData,
} from "../../../components/SigningComponent/SigningComponent";
import {
  base64toBlob,
  blobPdfFromBase64String,
  splitBase64,
  toUint8Array,
} from "../../../services/utilityServices";
import { ErrorModalService } from "../../../components/ErrorHandling/ErrorModalService";
import { saveAs } from "file-saver";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  defaultPredefinedLevel,
  defaultPredefinedStandard,
  SigningOptionsPredefined,
} from "../../../appsettings";
import SignatureParametersContext, {
  SignaturePosition,
} from "../../../store/SignatureParametersContext";

const StepThree = (props: any) => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const formState = useFormState();

  const navigation = useNavigate();

  const [stepThreeState, setStepThreeState] = useState({
    loading: true,
    showSpinner: true,
    errorState: false,
    errorMessage: "",
    signedFile: undefined,
    secondaryDrawerOpen: false,
    secondaryDrawerVideoIdent: false,
    secondaryDrawerBilling: false,
    showRetryButton: false,
  });
  const [widthForDrawer, setWidthForDrawer] = useState<number>(
    window.innerWidth
  );

  const signatureParamsCtx = useContext(SignatureParametersContext);

  //getting info of window width
  function handleWindowSizeChange() {
    setWidthForDrawer(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function listenForSigningResult(event: any) {
    if (event.data.type === "SIGNATOR_SIGNING_ERROR_VIDEO_IDENT") {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        showSpinner: false,
        errorState: true,
        errorMessage: event.data.error,
        signedFile: undefined,
        showRetryButton: false,
      }));
      /*setStepThreeState((prevState) => ({
        ...prevState,
        loading: true,
        showSpinner: true,
        errorState: false,
        errorMessage: event.data.error,
        signedFile: undefined,
        secondaryDrawerOpen: true,
        secondaryDrawerVideoIdent: true,
        secondaryDrawerBilling: false,
        showRetryButton: true,
      }));*/
    }
    if (event.data.type === "SIGNATOR_SIGNING_ERROR_NO_CREDITS") {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        showSpinner: false,
        errorState: true,
        errorMessage: event.data.error,
        signedFile: undefined,
        showRetryButton: true,
      }));
    }
    if (event.data.type === "SIGNATOR_SIGNING_ERROR") {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        showSpinner: false,
        errorState: true,
        errorMessage: event.data.error,
        signedFile: undefined,
        showRetryButton: true,
      }));
    }
    if (event.data.type === "SIGNATOR_SIGNING_SUCCESS") {
      setSuccessModalOpen(true);
      signatureParamsCtx.documentHasBeenSigned = true;
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        showSpinner: false,
        errorState: false,
        signedFile: event.data.signedFile,
      }));
    }
    if (event.data.type === "SIGNATOR_BILLING_PURCHASE_COMPLETED") {
      setStepThreeState((prevState) => ({
        ...prevState,
        secondaryDrawerOpen: false,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: false,
        showRetryButton: true,
      }));
    }
    if (event.data.status === "CLOSE_VIDEO_IDENT_IFRAME") {
      setStepThreeState((prevState) => ({
        ...prevState,
        errorState: false,
        errorMessage: event.data.error,
        signedFile: undefined,
        showSpinner: false,
        secondaryDrawerOpen: false,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: false,
        showRetryButton: true,
      }));
    }
    if (event.data.type === "SIGNATOR_VIDEO_IDENT_SUCCESS_FINISHED") {
      setStepThreeState((prevState) => ({
        ...prevState,
        secondaryDrawerOpen: false,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: false,
        showSpinner: false,
        showRetryButton: true,
      }));
    }
  }

  window.addEventListener("message", listenForSigningResult, false);

  useEffect(() => {
    if (signatureParamsCtx.directSignOption) {
      submitForm();
    } else if (props.signWithImageContinue) {
      props.addProfileStateHandler(false);

      formState.values["txtMobitel"] =
        props.signingImageProfile.txtMobitel! ?? "";
      formState.values["txtSignatureLevel"] =
        props.signingImageProfile.txtSignatureLevel ?? "";
      formState.values["txtSignatureProvider"] =
        props.signingImageProfile.txtSignatureProvider ?? "";
      formState.values["txtSignatureStandard"] =
        props.signingImageProfile.txtSignatureStandard ?? "";
      formState.values["txtName"] = props.signingImageProfile.txtName ?? "";
      formState.values["txtCompanyName"] =
        props.signingImageProfile.txtCompanyName ?? "";
      formState.values["txtDepartment"] =
        props.signingImageProfile.txtDepartment ?? "";
      formState.values["txtPosition"] =
        props.signingImageProfile.txtPosition ?? "";
      formState.values["txtLogo"] = props.signingImageProfile.txtLogo ?? "";
      formState.values["txtSignatureImagePicture"] =
        props.signingImageProfile.txtSignatureImagePicture ?? "";

      submitForm();
    } else {
      props.addProfileStateHandler(false);

      submitForm();
    }
  }, []);

  const saveDocumentHandler = (signedFile: any) => {
    if (signedFile !== undefined) {
      let blob = base64toBlob(signedFile, "application/pdf");
      saveAs(blob, "document_signed.pdf");
    } else {
      throw new Error(i18next.t("doc_not_available"));
    }
  };

  const closeWindowHandler = () => {
    setTimeout(() => {
      window.close();
    }, 300);

    setTimeout(() => {
      window.location.replace("https://arval.ch");
    }, 1500);
  };

  const backToDocumentHandler = async () => {
    props.removeSignatureHandler();
    props.closeDrawer();
    let blobPdf = (await blobPdfFromBase64String(
      stepThreeState.signedFile!
    )) as File;
    let convertPdf = await toUint8Array(blobPdf);
    signatureParamsCtx.pdfFile = convertPdf;
    navigation("/sign");
  };

  const submitForm = async () => {
    setTimeout(() => {
      setStepThreeState((prevState) => ({
        ...prevState,
        showSpinner: false,
      }));
    }, 1000);

    try {
      setStepThreeState((prevState) => ({
        ...prevState,
        showRetryButton: false,
      }));
      const { ...rest } = formState.values as FormModel;

      //if Direct Sign option is enabled, we are making new signature object with data from signature context (received in URL params)
      if (signatureParamsCtx.directSignOption) {
        try {
          let signPdfWithDirectOption = new PdfSignerComponent(
            signatureParamsCtx.pdfFileBase64!,
            new SignatureOptions("Reason", "Location"),
            signatureParamsCtx.signatureProvider,
            new SignatureProviderData(
              new SwisscomRequestData(
                signatureParamsCtx.phoneNumber!,
                signatureParamsCtx.jurisdiction! as Jurisdiction,
                signatureParamsCtx.signatureLevel! as SignatureLevel,
                undefined,
                undefined,
                undefined,
                i18next.language
              )
            ),
            new SignatureImage(
              signatureParamsCtx.pageForSignaturePosition!,
              signatureParamsCtx.signaturePosition ===
              SignaturePosition.PHASE_TWO
                ? 73.67
                : 92.33,
              signatureParamsCtx.signaturePosition ===
              SignaturePosition.PHASE_TWO
                ? 502.364
                : 495,
              57,
              114,
              signatureParamsCtx.signatureImageBase64Data!,
              signatureParamsCtx.firstName + " " + signatureParamsCtx.lastName,
              "",
              "",
              "",
              null
            ),
            signatureParamsCtx.signatureToken ?? undefined,
            undefined,
            signatureParamsCtx.targetIdentUrl ?? undefined,
            signatureParamsCtx.prepaid ?? undefined,
            i18next.language
          );

          let result = await signPdfWithDirectOption.signDocument();

          if (result === "ERROR") {
            setStepThreeState((prevState) => ({
              ...prevState,
              loading: false,
              showSpinner: false,
              errorState: true,
            }));
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        if (SigningOptionsPredefined === true) {
          if (
            (rest["txtSignatureStandard"] as Jurisdiction) !==
            defaultPredefinedStandard
          ) {
            setStepThreeState((prevState) => ({
              ...prevState,
              loading: false,
              showSpinner: false,
              errorState: true,
              errorMessage: `Invalid signature standard. Only ${defaultPredefinedStandard.toString()} standard is supported.`,
            }));
            return;
          } else if (rest["txtSignatureLevel"] !== defaultPredefinedLevel) {
            setStepThreeState((prevState) => ({
              ...prevState,
              loading: false,
              showSpinner: false,
              errorState: true,
              errorMessage: `Invalid signature level.  Only ${defaultPredefinedLevel.toString()} level is supported.`,
            }));
            return;
          }
        }

        let signature = new PdfSignerComponent(
          signatureParamsCtx.pdfFileBase64!,
          new SignatureOptions("Reason", "Location"),
          rest["txtSignatureProvider"] as SignatureProvider,
          new SignatureProviderData(
            new SwisscomRequestData(
              rest["txtMobitel"]!,
              rest["txtSignatureStandard"] as Jurisdiction,
              rest["txtSignatureLevel"] as SignatureLevel,
              undefined,
              undefined,
              undefined,
              i18next.language
            )
          ),
          rest["txtSignatureImagePicture"]
            ? new SignatureImage(
                props.signatureImage.page,
                props.signatureImage.offsetX,
                props.signatureImage.offsetY,
                props.signatureImage.height,
                props.signatureImage.width,
                splitBase64(rest["txtSignatureImagePicture"]!),
                rest["txtName"] ?? "",
                rest["txtPosition"] ?? "",
                rest["txtDepartment"] ?? "",
                rest["txtCompanyName"] ?? "",
                rest["txtLogo"] ?? null
              )
            : undefined,
          signatureParamsCtx.signatureToken ?? undefined,
          undefined,
          signatureParamsCtx.targetIdentUrl ?? undefined,
          signatureParamsCtx.prepaid ?? undefined,
          i18next.language
        );

        const result = await signature.signDocument();

        if (result === "ERROR") {
          setStepThreeState((prevState) => ({
            ...prevState,
            loading: false,
            showSpinner: false,
            errorState: true,
          }));
        }
      }
    } catch (e) {
      setStepThreeState((prevState) => ({
        ...prevState,
        showRetryButton: true,
      }));
      new ErrorModalService(5).showErrorModal();

      console.log(e);
    }
  };

  const signNewDocumentHandler = () => {
    window.location.replace(BASE_URL);
  };

  const retrySignatureHandler = () => {
    setStepThreeState((prevState) => ({
      ...prevState,
      loading: true,
      errorState: false,
      errorMessage: "",
      signedFile: undefined,
      secondaryDrawerOpen: false,
      secondaryDrawerVideoIdent: false,
      secondaryDrawerBilling: false,
      showRetryButton: false,
    }));

    submitForm();
  };

  return (
    <Fade>
      <Drawer
        title={
          stepThreeState.secondaryDrawerBilling
            ? i18next.t("purchase_credits")
            : i18next.t("identification")
        }
        width={
          widthForDrawer < 1200 ? (widthForDrawer < 700 ? "95%" : "70%") : "50%"
        }
        closable={true}
        onClose={() =>
          setStepThreeState((prevState) => ({
            ...prevState,
            secondaryDrawerOpen: false,
            secondaryDrawerVideoIdent: false,
            secondaryDrawerBilling: false,
            showSpinner: false,
            loading: true,
            showRetryButton: true,
          }))
        }
        placement="right"
        open={stepThreeState.secondaryDrawerOpen}
        visible={stepThreeState.secondaryDrawerOpen}
        destroyOnClose={true}
      >
        <div
          id={"signator-secondary-drawer"}
          style={{ height: "800px", maxHeight: "800px" }}
        ></div>
      </Drawer>
      {stepThreeState.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {stepThreeState.showSpinner ? (
            <Spin size="large" style={{ marginTop: "150px" }} />
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#623d91",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginTop: "35px",
                  textAlign: "center",
                }}
              >
                {i18next.t("digital_sig_confirmation")}
              </div>
              <div className={styles.Signator_Signature_Options_Info}>
                <div className={styles.Signator_SignatureOptionsInfoText}>
                  <div style={{ width: "50%" }}>
                    {i18next.t("sig_standard")}:
                  </div>
                  <div style={{ fontWeight: "bold", color: "#c2131c" }}>
                    {signatureParamsCtx.jurisdiction}
                  </div>
                </div>
                <div className={styles.Signator_SignatureOptionsInfoText}>
                  <div style={{ width: "50%" }}>
                    {i18next.t("sig_provider")}:
                  </div>
                  <div style={{ fontWeight: "bold", color: "#c2131c" }}>
                    {signatureParamsCtx.signatureProvider}
                  </div>
                </div>
                <div className={styles.Signator_SignatureOptionsInfoText}>
                  <div style={{ width: "50%" }}>{i18next.t("sig_level")}:</div>
                  <div style={{ fontWeight: "bold", color: "#c2131c" }}>
                    {signatureParamsCtx.signatureLevel}
                  </div>
                </div>
                <div className={styles.Signator_SignatureOptionsInfoText}>
                  <div style={{ width: "50%" }}>
                    {i18next.t("phone_number")}:
                  </div>
                  <div style={{ fontWeight: "bold", color: "#c2131c" }}>
                    {signatureParamsCtx.phoneNumber}
                  </div>
                </div>
                {stepThreeState.showRetryButton && (
                  <div
                    className={styles.SignatorRetryButton}
                    onClick={retrySignatureHandler}
                  >
                    <DriveFileRenameOutlineIcon
                      style={{ marginRight: "5px" }}
                    />
                    {i18next.t("try_again")}
                  </div>
                )}
              </div>
              <div
                id={"signator_drawer_container"}
                className={styles.Signator_Drawer_Container}
              ></div>
            </div>
          )}
        </div>
      ) : stepThreeState.errorState ? (
        <div className={styles.SignatorStepContainer}>
          <div className={styles.SignatorErrorTitle}>
            {i18next.t("undefined")}
          </div>
          <div className={styles.SignatorSuccessIcon}>
            <ErrorIcon style={{ width: "60%", height: "60%", color: "red" }} />
          </div>
          <div className={styles.SignatorErrorMessage}>
            {stepThreeState.errorMessage}
          </div>
          {stepThreeState.showRetryButton &&
            stepThreeState.errorMessage === i18next.t("20016_message") && (
              <div
                className={styles.SignatorSuccessButton}
                onClick={retrySignatureHandler}
                style={{ marginTop: "20px" }}
              >
                <DriveFileRenameOutlineIcon style={{ marginRight: "5px" }} />
                {i18next.t("try_again")}
              </div>
            )}
        </div>
      ) : (
        <div className={styles.SignatorStepContainer}>
          <div className={styles.SignatorSuccessTitle}>
            {i18next.t("success")}
            <br />
            {i18next.t("success_two")} <br />
            {i18next.t("success_three")}
          </div>
          <div className={styles.SignatorSuccessIcon}>
            <CheckCircleIcon
              style={{ width: "60%", height: "60%", color: "#13c2c2" }}
            />
          </div>
          <div className={styles.SignatorSuccessButtonsContainer}>
            <div
              className={styles.SignatorSuccessButton}
              onClick={() => saveDocumentHandler(stepThreeState.signedFile)}
            >
              <BrowserUpdatedIcon style={{ marginRight: "5px" }} />
              {i18next.t("download")}
            </div>
            <div
              className={styles.SignatorSuccessButton}
              onClick={closeWindowHandler}
            >
              <CancelIcon style={{ marginRight: "5px" }} />
              {i18next.t("close_label_lowercase")}
            </div>
          </div>
        </div>
      )}
    </Fade>
  );
};

export default StepThree;
