import React from "react";
import SettingsButtonMenu from "../SettingsButtonMenu/SettingsButtonMenu";
import styles from "./TopToolbar.module.css";
import ESignLiteLogo from "../../images/PVB_3.png";

function PdfViewerTopToolbar(props: any) {
  return (
    <div className={styles.Toolbar}>
      <div className={styles.LogoContainer}>
        <img
          className={styles.ToolbarLogo}
          src={ESignLiteLogo}
          alt="Vizibit logo"
        />
      </div>
      <div className={styles.Signator_ToolbarSlotsDiv}>
        <div
          className={styles.Signator_ToolbarSlotContainer}
          style={{ display: "flex", alignItems: "center", paddingTop: "5px" }}
        >
          <div style={{ padding: "0px 2px" }}>
            <props.slots.ZoomOut />
          </div>
          <div style={{ padding: "0px 2px" }}>
            <props.slots.Zoom />
          </div>
          <div style={{ padding: "0px 2px" }}>
            <props.slots.ZoomIn />
          </div>
          <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
            <props.slots.GoToPreviousPage />
          </div>
          <div style={{ padding: "0px 2px" }}>
            <props.slots.GoToNextPage />
          </div>
          <div style={{ padding: "0px 2px" }}>
            <props.slots.Download />
          </div>
          {!props.isMobile && (
            <div style={{ padding: "0px 2px" }}>
              <props.slots.Print />
            </div>
          )}
          {props.isMobile && (
            <div className={styles.Signator_ToolbarSlotMobileSettings}>
              <SettingsButtonMenu
                isSigAdded={props.addSignature}
                backgroundColor={"#f5f5f5"}
                isMobile={props.isMobile}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.Signator_BlankToolbarDiv}
        style={{ width: "33%" }}
      ></div>
    </div>
  );
}

export default PdfViewerTopToolbar;
