import classnames from "classnames";
import React, { useCallback, useRef } from "react";
import { Field } from "react-final-form";
import i18next from 'i18next'

import styles from "./Radio.module.css";
import { FieldValidator } from "final-form";

interface IRadioProps {
  label: string;
  name: string;
  radioIcon?: string;
  value: string;
  optional?: boolean;
  ariaDescription?: string;
  disabled?: boolean;
  validate?: FieldValidator<string>;
  defaultCheckedBool?: boolean;
  hidden?: boolean;
  nextStep?: Function;
}

export const Radio: React.FC<IRadioProps> = ({
  name,
  label,
  value,
  optional,
  radioIcon,
  defaultCheckedBool,
  ariaDescription,
  disabled = false,
  validate = undefined,
  hidden,
  nextStep,
}) => {
  const requiredValidation = useCallback(
    (value: string, _allValues: any) => {
      if (!optional && (value == null || value === "")) {
        return i18next.t("mandatory_field")
      } else {
        return undefined;
      }
    },
    [optional]
  );

  const validatorRef = useRef<FieldValidator<string>>(
    (value: string, allValues: any) => {
      const validations: Array<FieldValidator<string>> = [];

      if (!optional) {
        validations.push(requiredValidation);
      }
      if (validate != null) {
        validations.push(validate);
      }

      return validations.reduce(
        (message: string | undefined, fn) =>
          message == null ? fn(value, allValues) : message,
        undefined
      );
    }
  );

  return (
    <Field
      name={name}
      type="radio"
      value={value}
      validate={validatorRef.current}
    >
      {({ input, meta }) => (
        <div
          className={classnames(styles.Radio, {
            "custom-control custom-radio radio-as-icon": true,
            "is-invalid": meta.touched && meta.error,
          })}
        >
          <input
            id={`id-${name}-${value}`}
            name={input.name}
            hidden={hidden}
            type="radio"
            value={input.value}
            defaultChecked={defaultCheckedBool}
            onChange={(event) => {
              document
                .querySelectorAll("input[name=" + event.target.name + "]")
                ?.forEach((element) =>
                  (element as HTMLInputElement).setCustomValidity("")
                );
              input.onChange(event);
            }}
            onInvalid={(event) =>
              (event.target as HTMLSelectElement).setCustomValidity("Required")
            }
            className={classnames(styles.SignatorInputRadio, "btn-check")}
            required={optional ? "" : ("required" as any)}
            aria-describedby={ariaDescription}
            disabled={disabled}
          />
          <label
            className={styles.SignatureStandardRadioLabelWide}
            htmlFor={`id-${name}-${value}`}
            onClick={() => {
              if (nextStep) nextStep();
            }}
          >
            {radioIcon && (
              <img
                src={radioIcon}
                className={styles.SignatureStandardRadioFlag}
              />
            )}
            {label}
          </label>
          {meta.touched && meta.error && (
            <div className="invalid-feedback">{meta.error}</div>
          )}
          {ariaDescription && (
            <span
              id={`help-${name}`}
              className={classnames(
                "form-text text-muted",
                styles.AriaDescriptionSpecial
              )}
            >
              {ariaDescription}
            </span>
          )}
        </div>
      )}
    </Field>
  );
};
