import classnames from "classnames";
import { FieldValidator } from "final-form";
import React, { useCallback, useRef } from "react";
import { Field } from "react-final-form";

import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import styles from "./Telephone.module.css";
import i18next from "i18next";

interface ITelephoneProps {
  label?: string;
  className?: string;
  name: string;
  placeholder?: string;
  optional?: boolean;
  ariaDescription?: string;
  type?: "date" | "text" | "number";
  disabled?: boolean;
  dependantValue?: any;
  validate?: FieldValidator<string>;
}

export const Telephone: React.FC<ITelephoneProps> = ({
  type = "text",
  disabled = false,
  optional = false,
  name,
  label,
  placeholder,
  ariaDescription,
  className = "",
  validate = undefined,
}) => {
  const requiredValidation = useCallback(
    (value: string, _allValues: any) => {
      if (!optional && [undefined, null, "", "+"].includes(value)) {
        return i18next.t("required_field");
      } else if (!isPossiblePhoneNumber(value)) {
        return i18next.t("invalid_phone_number");
      } else {
        return undefined;
      }
    },
    [optional]
  );

  const validatorRef = useRef<FieldValidator<string>>(
    (value: string, allValues: any) => {
      const validations: Array<FieldValidator<string>> = [];

      if (!optional) {
        validations.push(requiredValidation);
      }
      if (validate != null) {
        validations.push(validate);
      }

      return validations.reduce(
        (message: string | undefined, fn) =>
          message == null ? fn(value, allValues) : message,
        undefined
      );
    }
  );

  return (
    <Field name={name} validate={validatorRef.current}>
      {({ input, meta }) => (
        <div className={classnames(styles.Telephone, className)}>
          {label && <label htmlFor={name}>{label}</label>}
          <PhoneInput
            defaultCountry="CH"
            id={`id-${name}`}
            className={classnames({
              [styles.NoPadding]: true,
              "form-control": true,
              "is-invalid": meta.touched && meta.error,
            })}
            type={type}
            {...(input as any)}
            placeholder={placeholder}
            aria-describedby={ariaDescription}
            disabled={disabled}
            international
          />
          {meta.touched && meta.error && (
            <div className="invalid-feedback">{meta.error}</div>
          )}
          {ariaDescription && (
            <span
              id={`help-${name}`}
              className={classnames(
                "form-text text-muted",
                styles.AriaDescription
              )}
            >
              {ariaDescription}
            </span>
          )}
        </div>
      )}
    </Field>
  );
};
