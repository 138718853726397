import axios, { AxiosError } from "axios";
import { ProcessSigning } from "./ProcessSigning";
import {
  BASE_URL,
  EnabledSignatureProviders,
  PaymentAndIdentCheckEnabled,
} from "../../appsettings";
import i18next from "i18next";
import { AxiosErrorException } from "../ErrorHandling/AxiosErrorException";

export class SignatureOptions {
  reason: string | undefined;
  location: string | undefined;
  signerContactInfo: string | undefined;
  signerName: string | undefined;

  constructor(
    reason?: string,
    location?: string,
    signerContactInfo?: string,
    signerName?: string
  ) {
    this.reason = reason;
    this.location = location;
    this.signerContactInfo = signerContactInfo;
    this.signerName = signerName;
  }
}

export class SignatureProviderData {
  swisscomRequestData: SwisscomRequestData | undefined;

  constructor(swisscomRequestData?: SwisscomRequestData | undefined) {
    this.swisscomRequestData = swisscomRequestData;
  }
}

export class SwisscomRequestData {
  mobileNumber: string;
  language: string | undefined;
  message: string | undefined;
  jurisdiction: Jurisdiction;
  signatureLevel: SignatureLevel;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;

  constructor(
    mobileNumber: string,
    jurisdiction: Jurisdiction,
    signatureLevel: SignatureLevel,
    firstName?: string,
    lastName?: string,
    email?: string,
    language?: string,
    message?: string
  ) {
    this.mobileNumber = mobileNumber;
    this.language = language;
    this.message = message;
    this.jurisdiction = jurisdiction;
    this.signatureLevel = signatureLevel;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
}

export class SignatureImage {
  page: number;
  widgetOffsetX: number;
  widgetOffsetY: number;
  widgetHeight: number;
  widgetWidth: number;
  imageData: string;
  lineOne: string | undefined;
  lineTwo: string | undefined;
  lineThree: string | undefined;
  lineFour: string | undefined;
  logoImage: string | undefined | null;

  constructor(
    page: number,
    widgetOffsetX: number,
    widgetOffsetY: number,
    widgetHeight: number,
    widgetWidth: number,
    imageData: string,
    lineOne?: string,
    lineTwo?: string,
    lineThree?: string,
    lineFour?: string,
    logoImage?: string | null
  ) {
    this.page = page;
    this.widgetOffsetX = widgetOffsetX;
    this.widgetOffsetY = widgetOffsetY;
    this.widgetHeight = widgetHeight;
    this.widgetWidth = widgetWidth;
    this.imageData = imageData;
    this.lineOne = lineOne;
    this.lineTwo = lineTwo;
    this.lineThree = lineThree;
    this.lineFour = lineFour;
    this.logoImage = logoImage;
  }
}

export class SignatureResponseData {
  OperationId: string | undefined;
  OperationData: any;
  Status: SignatureStatus | undefined;
  SignatureProvider: SignatureProvider | undefined;
}

export enum SignatureStatus {
  INITIALIZED = "Initialized",
  WAITINGFORUSERAPPROVAL = "WaitingForUserApproval",
  DELIVERED = "Delivered",
  FAILED = "Failed",
  UNKNOWN = "Unknown",
}

export enum Jurisdiction {
  ZERTES = "ZERTES",
  EIDAS = "EIDAS",
}

export enum SignatureLevel {
  AES = "AES",
  QES = "QES",
  SES = "SES",
}

export enum SignatureProvider {
  SwisscomAis = "SwisscomAis",
  Certilia = "Certilia",
  CertiliaRdd = "CertiliaRdd",
  ExternalLocalComponent = "ExternalLocalComponent",
}

export enum UserDevice {
  MOBILE = "MOBILE",
  DESKTOP = "DESKTOP",
}

export enum IdentificationProvider {
  ID_NOW = "ID_NOW",
  IDENTITY_TM = "IDENTITY_TM",
}
function throwProviderError() {
  window.parent.postMessage({
    type: "SIGNATOR_SIGNING_ERROR",
    error: i18next.t("params_error"),
  });
  return "ERROR";
}

export default class PdfSignerComponent {
  private readonly document: string;
  private readonly signatureOptions: SignatureOptions | undefined;
  private readonly signatureProvider: SignatureProvider;
  private readonly signatureData: SignatureProviderData;
  private readonly endpointUrl: string;
  private readonly signatureImage?: SignatureImage;
  private readonly identificationEndpointUrl: string;
  private readonly billingUrl: string;
  private readonly signatureToken?: string;
  private readonly targetIdentUrl?: string;
  private readonly prepaid: string;
  private readonly language?: string;
  private readonly device?: UserDevice;
  private readonly identificationProvider?: IdentificationProvider;
  private readonly amountToSign?: string;
  private signatureResponseUrl: string | undefined;
  private signatureTimer: any;

  constructor(
    document: string,
    signatureOptions: SignatureOptions | undefined,
    signatureProvider: SignatureProvider,
    signatureData: SignatureProviderData,
    signatureImage?: SignatureImage,
    signatureToken?: string,
    device?: UserDevice,
    targetIdentUrl?: string,
    prepaid?: string,
    language?: string,
    identificationProvider?: IdentificationProvider,
    onSigned?: Function,
    onError?: Function,
    endpointUrl?: string,
    identificationEndpointUrl?: string,
    billingUrl?: string,
    amountToSign?: string | undefined
  ) {
    this.document = document;
    this.signatureOptions = signatureOptions;
    this.signatureProvider = signatureProvider;
    this.signatureData = signatureData;
    this.signatureImage = signatureImage;
    this.signatureToken = signatureToken;
    this.endpointUrl = endpointUrl ?? BASE_URL + "/v/1/signer/pdf";
    this.identificationEndpointUrl =
      identificationEndpointUrl ?? BASE_URL + "/v/1/identification/lookup";
    this.billingUrl =
      billingUrl ?? BASE_URL + "/v/1/product_provisioning/available_credits";
    this.device = device ?? UserDevice.DESKTOP;
    this.targetIdentUrl = targetIdentUrl ?? undefined;
    this.prepaid = prepaid ?? "true";
    this.language = language;
    this.identificationProvider =
      identificationProvider ?? IdentificationProvider.ID_NOW;
    this.amountToSign = amountToSign ?? "1";

    if (signatureProvider === SignatureProvider.SwisscomAis) {
      if (!this.signatureData?.swisscomRequestData?.mobileNumber) {
        window.parent.postMessage({
          type: "SIGNATOR_SIGNING_ERROR",
          error: i18next.t("define_swisscom_error"),
        });
        throw new Error(i18next.t("define_swisscom_error"));
      }
    }
  }

  public async signDocument() {
    let result;

    //CHECK IF CHOSEN PROVIDER IS ENABLED IN APP SETTINGS
    if (
      this.signatureProvider === SignatureProvider.SwisscomAis &&
      !EnabledSignatureProviders.SwisscomEnabled
    ) {
      throwProviderError();
      return;
    }

    if (
      !EnabledSignatureProviders.CertiliaEnabled &&
      this.signatureProvider === SignatureProvider.Certilia
    ) {
      throwProviderError();
      return;
    }

    if (
      !EnabledSignatureProviders.CertiliaRddEnabled &&
      this.signatureProvider === SignatureProvider.CertiliaRdd
    ) {
      throwProviderError();
      return;
    }

    if (
      this.signatureData.swisscomRequestData?.jurisdiction !==
        Jurisdiction.ZERTES &&
      this.signatureData.swisscomRequestData?.jurisdiction !==
        Jurisdiction.EIDAS
    ) {
      throwProviderError();
      return;
    }

    if (
      this.signatureData.swisscomRequestData?.signatureLevel !==
        SignatureLevel.SES &&
      this.signatureData.swisscomRequestData?.signatureLevel !==
        SignatureLevel.AES &&
      this.signatureData.swisscomRequestData?.signatureLevel !==
        SignatureLevel.QES
    ) {
      throwProviderError();
      return;
    }

    //PAYMENT ENABLED FLOW WITH SWISSCOM AS PROVIDER
    if (
      PaymentAndIdentCheckEnabled &&
      this.signatureProvider === SignatureProvider.SwisscomAis
    ) {
      try {
        await axios
          .post(this.identificationEndpointUrl, {
            Jurisdiction: this.signatureData.swisscomRequestData?.jurisdiction,
            SignatureLevel:
              this.signatureData.swisscomRequestData?.signatureLevel,
            SignatureToken: this.signatureToken ?? "",
            MobileNumber: this.signatureData.swisscomRequestData?.mobileNumber,
            IdentificationDevice: this.device,
          })
          .then(async (response) => {
            if (response.status === 200) {
              try {
                await axios
                  .post(this.billingUrl, {
                    amount: this.amountToSign,
                    signatureToken: this.signatureToken ?? null,
                    phoneNumber:
                      this.signatureData.swisscomRequestData?.mobileNumber,
                    signatureInformation: {
                      signatureProvider: this.signatureProvider,
                      jurisdictionType:
                        this.signatureData.swisscomRequestData!.jurisdiction,
                      signatureLevel:
                        this.signatureData.swisscomRequestData!.signatureLevel,
                      productType: 0,
                    },
                  })
                  .then(async () => {
                    if (
                      response &&
                      response.data?.UserHasSignatures === false
                    ) {
                      window.parent.postMessage({
                        type: "SIGNATOR_SIGNING_ERROR",
                        error: i18next.t("unable_sign_error"),
                      });
                      console.log("ERROR_MESSAGE");
                      return (result = "ERROR");
                    }
                    try {
                      //IF SIGNATURE TOKEN IS NOT DEFINED, THEN WE ARE NOT SENDING SIGNATURE TOKEN IN REQUEST BODY AND WE ARE SENDING PDF DOCUMENT
                      if (
                        this.signatureToken === null ||
                        this.signatureToken === undefined
                      ) {
                        await axios
                          .post(this.endpointUrl, {
                            SignatureProvider: this.signatureProvider,
                            SignatureProviderData: {
                              SwisscomRequestData:
                                this.signatureData.swisscomRequestData,
                            },
                            Documents: [
                              {
                                SignatureOptions: {
                                  Reason: undefined,
                                  Location: undefined,
                                  SignerContactInfo: undefined,
                                  SignerName: undefined,
                                },
                                SignatureImage: {
                                  Page: this.signatureImage?.page,
                                  WidgetOffsetX:
                                    this.signatureImage?.widgetOffsetX,
                                  WidgetOffsetY:
                                    this.signatureImage?.widgetOffsetY,
                                  WidgetHeight:
                                    this.signatureImage?.widgetHeight,
                                  WidgetWidth: this.signatureImage?.widgetWidth,
                                  ImageData: this.signatureImage?.imageData,
                                  LineOne: this.signatureImage?.lineOne,
                                  LineTwo: this.signatureImage?.lineTwo,
                                  LineThree: this.signatureImage?.lineThree,
                                  LineFour: this.signatureImage?.lineFour,
                                  LogoImageData:
                                    this.signatureImage?.logoImage ?? null,
                                  Appearance: "CARD",
                                },
                                Document: this.document,
                              },
                            ],
                          })
                          .then((response) => {
                            this.signatureResponseUrl =
                              response.data.SignatureUrl;
                            this.signatureTimer = setInterval(
                              () =>
                                new ProcessSigning(
                                  this.signatureResponseUrl!,
                                  this.signatureTimer,
                                  this.signatureData.swisscomRequestData?.jurisdiction,
                                  this.signatureData.swisscomRequestData?.signatureLevel,
                                  this.signatureToken ?? "",
                                  this.signatureData.swisscomRequestData
                                    ?.mobileNumber ?? "",
                                  this.device ?? UserDevice.DESKTOP,
                                  this.language
                                ).processSigning(),
                              1000
                            );
                            return (result = "SUCCESS");
                          })
                          .catch((e: AxiosError) => {
                            new AxiosErrorException(e).throwError();
                            return (result = "ERROR");
                          });
                      }
                      //SIGNATURE TOKEN IS DEFINED, WE ARE SENDING IT AS A PART OF REQ. BODY, AND WE ARE NOT SENDING PDF DOCUMENT
                      else {
                        await axios
                          .post(this.endpointUrl, {
                            SignatureProvider: this.signatureProvider,
                            SignatureProviderData: {
                              SwisscomRequestData:
                                this.signatureData.swisscomRequestData,
                            },
                            Documents: [
                              {
                                SignatureOptions: {
                                  Reason: undefined,
                                  Location: undefined,
                                  SignerContactInfo: undefined,
                                  SignerName: undefined,
                                },
                                SignatureImage: {
                                  Page: this.signatureImage?.page,
                                  WidgetOffsetX:
                                    this.signatureImage?.widgetOffsetX,
                                  WidgetOffsetY:
                                    this.signatureImage?.widgetOffsetY,
                                  WidgetHeight:
                                    this.signatureImage?.widgetHeight,
                                  WidgetWidth: this.signatureImage?.widgetWidth,
                                  ImageData: this.signatureImage?.imageData,
                                  LineOne: this.signatureImage?.lineOne,
                                  LineTwo: this.signatureImage?.lineTwo,
                                  LineThree: this.signatureImage?.lineThree,
                                  LineFour: this.signatureImage?.lineFour,
                                  LogoImageData:
                                    this.signatureImage?.logoImage ?? null,
                                  Appearance: "CARD",
                                },
                                SignatureToken: this.signatureToken,
                              },
                            ],
                          })
                          .then((response) => {
                            this.signatureResponseUrl =
                              response.data.SignatureUrl;
                            this.signatureTimer = setInterval(
                              () =>
                                new ProcessSigning(
                                  this.signatureResponseUrl!,
                                  this.signatureTimer,
                                  this.signatureData.swisscomRequestData?.jurisdiction,
                                  this.signatureData.swisscomRequestData?.signatureLevel,
                                  this.signatureToken ?? "",
                                  this.signatureData.swisscomRequestData
                                    ?.mobileNumber ?? "",
                                  this.device ?? UserDevice.DESKTOP,
                                  this.language
                                ).processSigning(),
                              1000
                            );
                            return (result = "SUCCESS");
                          })
                          .catch((e: AxiosError) => {
                            new AxiosErrorException(e).throwError();
                            return (result = "ERROR");
                          });
                      }
                    } catch (e: any) {
                      window.parent.postMessage({
                        type: "SIGNATOR_SIGNING_ERROR",
                        error: i18next.t("unable_sign_error"),
                      });
                      console.log("ERROR_MESSAGE", e);
                      return (result = "ERROR");
                    }
                  });
              } catch (e: any) {
                window.parent.postMessage({
                  type: "SIGNATOR_SIGNING_ERROR",
                  error: i18next.t("unable_sign_error"),
                });
                console.log("ERROR_MESSAGE", e);
                return (result = "ERROR");
              }
            }
          })
          .catch((e: AxiosError) => {
            new AxiosErrorException(e).throwError();
            return (result = "ERROR");
          });
      } catch (e: any) {
        window.parent.postMessage({
          type: "SIGNATOR_SIGNING_ERROR",
          error: i18next.t("unable_sign_error"),
        });
        console.log("ERROR_MESSAGE", e);
        return (result = "ERROR");
      }
    }
    //PAYMENT DISABLED FLOW WITH SWISSCOM AS PROVIDER
    else if (
      !PaymentAndIdentCheckEnabled &&
      this.signatureProvider === SignatureProvider.SwisscomAis
    ) {
      try {
        //IF SIGNATURE TOKEN IS NOT DEFINED, THEN WE ARE NOT SENDING SIGNATURE TOKEN IN REQUEST BODY AND WE ARE SENDING PDF DOCUMEN
        if (this.signatureToken === null || this.signatureToken === undefined) {
          await axios
            .post(this.endpointUrl, {
              SignatureProvider: this.signatureProvider,
              SignatureProviderData: {
                SwisscomRequestData: this.signatureData.swisscomRequestData,
              },
              Documents: [
                {
                  SignatureOptions: {
                    Reason: undefined,
                    Location: undefined,
                    SignerContactInfo: undefined,
                    SignerName: undefined,
                  },
                  SignatureImage: {
                    Page: this.signatureImage?.page,
                    WidgetOffsetX: this.signatureImage?.widgetOffsetX,
                    WidgetOffsetY: this.signatureImage?.widgetOffsetY,
                    WidgetHeight: this.signatureImage?.widgetHeight,
                    WidgetWidth: this.signatureImage?.widgetWidth,
                    ImageData: this.signatureImage?.imageData,
                    LineOne: this.signatureImage?.lineOne,
                    LineTwo: this.signatureImage?.lineTwo,
                    LineThree: this.signatureImage?.lineThree,
                    LineFour: this.signatureImage?.lineFour,
                    LogoImageData: this.signatureImage?.logoImage ?? null,
                    Appearance: "CARD",
                  },
                  Document: this.document,
                },
              ],
            })
            .then((response) => {
              this.signatureResponseUrl = response.data.SignatureUrl;
              this.signatureTimer = setInterval(
                () =>
                  new ProcessSigning(
                    this.signatureResponseUrl!,
                    this.signatureTimer,
                    this.signatureData.swisscomRequestData?.jurisdiction,
                    this.signatureData.swisscomRequestData?.signatureLevel,
                    this.signatureToken ?? "",
                    this.signatureData.swisscomRequestData?.mobileNumber ?? "",
                    this.device ?? UserDevice.DESKTOP,
                    this.language
                  ).processSigning(),
                1000
              );
              return (result = "SUCCESS");
            })
            .catch((e: AxiosError) => {
              new AxiosErrorException(e).throwError();
              return (result = "ERROR");
            });
        }
        //SIGNATURE TOKEN IS DEFINED, WE ARE SENDING IT AS A PART OF REQ. BODY, AND WE ARE NOT SENDING PDF DOCUMENT
        else {
          await axios
            .post(this.endpointUrl, {
              SignatureProvider: this.signatureProvider,
              SignatureProviderData: {
                SwisscomRequestData: this.signatureData.swisscomRequestData,
              },
              Documents: [
                {
                  SignatureOptions: {
                    Reason: undefined,
                    Location: undefined,
                    SignerContactInfo: undefined,
                    SignerName: undefined,
                  },
                  SignatureImage: {
                    Page: this.signatureImage?.page,
                    WidgetOffsetX: this.signatureImage?.widgetOffsetX,
                    WidgetOffsetY: this.signatureImage?.widgetOffsetY,
                    WidgetHeight: this.signatureImage?.widgetHeight,
                    WidgetWidth: this.signatureImage?.widgetWidth,
                    ImageData: this.signatureImage?.imageData,
                    LineOne: this.signatureImage?.lineOne,
                    LineTwo: this.signatureImage?.lineTwo,
                    LineThree: this.signatureImage?.lineThree,
                    LineFour: this.signatureImage?.lineFour,
                    LogoImageData: this.signatureImage?.logoImage ?? null,
                    Appearance: "CARD",
                  },
                  SignatureToken: this.signatureToken,
                },
              ],
            })
            .then((response) => {
              this.signatureResponseUrl = response.data.SignatureUrl;
              this.signatureTimer = setInterval(
                () =>
                  new ProcessSigning(
                    this.signatureResponseUrl!,
                    this.signatureTimer,
                    this.signatureData.swisscomRequestData?.jurisdiction,
                    this.signatureData.swisscomRequestData?.signatureLevel,
                    this.signatureToken ?? "",
                    this.signatureData.swisscomRequestData?.mobileNumber ?? "",
                    this.device ?? UserDevice.DESKTOP,
                    this.language
                  ).processSigning(),
                1000
              );
              return (result = "SUCCESS");
            })
            .catch((e: AxiosError) => {
              new AxiosErrorException(e).throwError();
              return (result = "ERROR");
            });
        }
      } catch (e: any) {
        window.parent.postMessage({
          type: "SIGNATOR_SIGNING_ERROR",
          error: i18next.t("unable_sign_error"),
        });
        console.log("ERROR_MESSAGE", e);
        return (result = "ERROR");
      }
    }
    //PAYMENT DISABLED FLOW NOT SWISSCOM AS PROVIDER
    else {
      //Provider not Swisscom
      try {
        //IF SIGNATURE TOKEN IS NOT DEFINED, THEN WE ARE NOT SENDING SIGNATURE TOKEN IN REQUEST BODY AND WE ARE SENDING PDF DOCUMEN
        if (this.signatureToken === null || this.signatureToken === undefined) {
          await axios
            .post(this.endpointUrl, {
              SignatureProvider: this.signatureProvider,
              SignatureProviderData: {
                SwisscomRequestData: this.signatureData.swisscomRequestData,
              },
              Documents: [
                {
                  SignatureOptions: {
                    Reason: undefined,
                    Location: undefined,
                    SignerContactInfo: undefined,
                    SignerName: undefined,
                  },
                  SignatureImage: {
                    Page: this.signatureImage?.page,
                    WidgetOffsetX: this.signatureImage?.widgetOffsetX,
                    WidgetOffsetY: this.signatureImage?.widgetOffsetY,
                    WidgetHeight: this.signatureImage?.widgetHeight,
                    WidgetWidth: this.signatureImage?.widgetWidth,
                    ImageData: this.signatureImage?.imageData,
                    LineOne: this.signatureImage?.lineOne,
                    LineTwo: this.signatureImage?.lineTwo,
                    LineThree: this.signatureImage?.lineThree,
                    LineFour: this.signatureImage?.lineFour,
                    LogoImageData: this.signatureImage?.logoImage ?? null,
                    Appearance: "CARD",
                  },
                  Document: this.document,
                },
              ],
            })
            .then((response) => {
              this.signatureResponseUrl = response.data.SignatureUrl;
              this.signatureTimer = setInterval(
                () =>
                  new ProcessSigning(
                    this.signatureResponseUrl!,
                    this.signatureTimer,
                    this.signatureData.swisscomRequestData?.jurisdiction,
                    this.signatureData.swisscomRequestData?.signatureLevel,
                    this.signatureToken ?? "",
                    this.signatureData.swisscomRequestData?.mobileNumber ?? "",
                    this.device ?? UserDevice.DESKTOP,
                    this.language
                  ).processSigning(),
                1000
              );
              return (result = "SUCCESS");
            })
            .catch((e: AxiosError) => {
              new AxiosErrorException(e).throwError();
              return (result = "ERROR");
            });
        }
        //SIGNATURE TOKEN IS DEFINED, WE ARE SENDING IT AS A PART OF REQ. BODY, AND WE ARE NOT SENDING PDF DOCUMENT
        else {
          await axios
            .post(this.endpointUrl, {
              SignatureProvider: this.signatureProvider,
              SignatureProviderData: {
                SwisscomRequestData: this.signatureData.swisscomRequestData,
              },
              Documents: [
                {
                  SignatureOptions: {
                    Reason: undefined,
                    Location: undefined,
                    SignerContactInfo: undefined,
                    SignerName: undefined,
                  },
                  SignatureImage: {
                    Page: this.signatureImage?.page,
                    WidgetOffsetX: this.signatureImage?.widgetOffsetX,
                    WidgetOffsetY: this.signatureImage?.widgetOffsetY,
                    WidgetHeight: this.signatureImage?.widgetHeight,
                    WidgetWidth: this.signatureImage?.widgetWidth,
                    ImageData: this.signatureImage?.imageData,
                    LineOne: this.signatureImage?.lineOne,
                    LineTwo: this.signatureImage?.lineTwo,
                    LineThree: this.signatureImage?.lineThree,
                    LineFour: this.signatureImage?.lineFour,
                    LogoImageData: this.signatureImage?.logoImage ?? null,
                    Appearance: "CARD",
                  },
                  SignatureToken: this.signatureToken,
                },
              ],
            })
            .then((response) => {
              this.signatureResponseUrl = response.data.SignatureUrl;
              this.signatureTimer = setInterval(
                () =>
                  new ProcessSigning(
                    this.signatureResponseUrl!,
                    this.signatureTimer,
                    this.signatureData.swisscomRequestData?.jurisdiction,
                    this.signatureData.swisscomRequestData?.signatureLevel,
                    this.signatureToken ?? "",
                    this.signatureData.swisscomRequestData?.mobileNumber ?? "",
                    this.device ?? UserDevice.DESKTOP,
                    this.language
                  ).processSigning(),
                1000
              );
              return (result = "SUCCESS");
            })
            .catch((e: AxiosError) => {
              new AxiosErrorException(e).throwError();
              return (result = "ERROR");
            });
        }
      } catch (e) {
        window.parent.postMessage({
          type: "SIGNATOR_SIGNING_ERROR",
          error: i18next.t("unable_sign_error"),
        });
        console.log("ERROR_MESSAGE", e);
        return (result = "ERROR");
      }
    }

    return result;
  }
}
