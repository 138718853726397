import React, { useRef, useState, useContext } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import styles from "./SignButton.module.css";
import IntroTooltip from "../IntroTooltip/IntroTooltip";
import IntroTooltipContext from "../../store/IntroTooltipContext";
import i18next from "i18next";
import {Tooltip} from "antd";

const options = ["Sign"];

const SignButtonMenu = (props: any) => {
  const tooltipCtx = useContext(IntroTooltipContext);

  //refs
  const signButtonDropdownAnchorRef = useRef<HTMLDivElement>(null);

  //handlers
  const handleClick = () => {
    if (props.isSigAdded) {
      props.signWithImage();
    } else {
      props.signWithoutImageModalHandler();
    }
  };

  return (
    <Tooltip title={i18next.t('scroll_to_sign')}>
      <ButtonGroup
        variant="contained"
        ref={signButtonDropdownAnchorRef}
        aria-label="split button"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Button
          className={
            props.isSigAdded
              ? styles.Signator_SignButtonAnimation
              : styles.Signator_SignButtonNoAnimation
          }
          onClick={handleClick}
          style={{
            backgroundColor: props.userHitTheBottom ? "#1cbeda" : "#dde0e1",
            borderColor: "white",
            padding: "6px",
            color: "white",
            fontSize: "16px",
            marginLeft: "10px",
            borderRadius: "12px",
            height: "40px",
            minWidth: props.isMobile ? "70px" : "100px",
            fontWeight: "bold",
            fontFamily: "OpenSans",
            textTransform: "none",
          }}
          disabled={!props.userHitTheBottom}
        >
          {i18next.t("sign_label")}
        </Button>
      </ButtonGroup>
    </Tooltip>
  );
};

export default SignButtonMenu;
