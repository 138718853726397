import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  DocumentLoadEvent,
  LoadError,
  Plugin,
  RenderPage,
  RenderPageProps,
  SpecialZoomLevel,
  Viewer,
  Worker,
  ZoomEvent,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  toolbarPlugin,
  ToolbarProps,
  ToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import DefaultPdf from "../assets/blank.pdf";
import { base64_arraybuffer, splitBase64 } from "../services/utilityServices";
import styles from "./Signer.module.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/drop/lib/styles/index.css";
import { Modal, Spin } from "antd";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import Moveable from "react-moveable";
import { ErrorModalService } from "../components/ErrorHandling/ErrorModalService";
import SignButtonMenu from "../components/SignButtonMenu/SignButtonMenu";
import SigningWizard from "./SigningWizard/SigningWizard";
import SigImageTemplateEN from "../images/sigImageTemplate_EN.png";
import RestartVideoIdent from "../components/RestartVideoIdentComponent/RestartVideoIdent";
import RejectButton from "../components/RejectButton/RejectButton";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { LoadingOutlined } from "@ant-design/icons";
import { BASE_URL, enableTooltipComponent, REDIRECT_URL } from "../appsettings";
import i18next from "i18next";
import SignatureParametersContext from "../store/SignatureParametersContext";
import SignatureMoveable from "../components/SignatureMoveable/SignatureMoveable";
import PdfViewerTopToolbar from "../components/PdfViewerTopToolbar/PdfViewerTopToolbar";
import SignContextMenu from "../components/SignContextMenu/SignContextMenu";
import IntroTooltipContext from "../store/IntroTooltipContext";
import IntroTooltip from "../components/IntroTooltip/IntroTooltip";
import IntroModal from "../components/IntroModal/IntroModal";
import axios from "axios";

//reject modal
const rejectInfo = (storageId: string) => {
  Modal.confirm({
    title: i18next.t("reject_signing_header"),
    content: (
      <div>
        <p>{i18next.t("reject_signing_info")}</p>
      </div>
    ),
    async onOk() {
      await axios
        .put(BASE_URL + `/v/1/signature/workflow/reject/${storageId}`)
        .then(() => {
          rejectSuccessInfo();
        })
        .catch((_) => {
          rejectUnsuccessInfo();
        });
    },
    okText: i18next.t("yes_label"),
    cancelText: i18next.t("no_label"),
    closable: true,
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

//reject successful modal
const rejectSuccessInfo = () => {
  Modal.success({
    title: i18next.t("reject_success"),
    content: (
      <div>
        <p>{i18next.t("reject_success_text")}</p>
      </div>
    ),
    onOk() {
      setTimeout(() => {
        window.close();
      }, 300);

      setTimeout(() => {
        window.location.replace("https://arval.ch");
      }, 1500);
    },
    okText: "OK",
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

//reject unsuccessful modal
const rejectUnsuccessInfo = () => {
  Modal.error({
    title: i18next.t("reject_failed"),
    content: (
      <div>
        <p>{i18next.t("reject_failed_text")}</p>
      </div>
    ),
    closable: true,
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

const signatureFrameReducer = (state: any, action: any) => {
  if (action.type === "ON_ZOOM") {
    let scaleDiff = action.scale / state.scale;
    return {
      size: [state.size[0] * scaleDiff, state.size[1] * scaleDiff],
      translate: [
        state.translate[0] * scaleDiff,
        state.translate[1] * scaleDiff,
      ],
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: action.scale,
      opacity: action.opacity,
      signaturePage: state.signaturePage,
    };
  }
  if (action.type === "ON_LOAD_TRANSLATE") {
    return {
      size: state.size,
      translate: action.translate,
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: state.scale,
      opacity: state.opacity,
      signaturePage: state.signaturePage,
    };
  }
  if (action.type === "ON_RESIZE") {
    let scaleDiff = action.scale / state.scale;
    return {
      size: [action.size[0] * scaleDiff, action.size[1] * scaleDiff],
      translate: action.translate,
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: action.scale,
      opacity: state.opacity,
      signaturePage: state.signaturePage,
    };
  }
  if (action.type === "ON_DRAG") {
    return {
      size: state.size,
      translate: action.translate,
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: state.scale,
      opacity: state.opacity,
      signaturePage: state.signaturePage,
    };
  }
  if (action.type === "ON_PAGE_CHANGE_UP") {
    document.body.style.cursor = "wait";
    setTimeout(() => {
      let signatureImageElement = document.getElementById("signature-field");
      if (signatureImageElement) {
        signatureImageElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 500);
    return {
      size: action.size,
      translate: action.translate,
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: state.scale,
      opacity: action.opacity,
      signaturePage: action.signaturePage,
    };
  }
  if (action.type === "ON_PAGE_CHANGE_DOWN") {
    document.body.style.cursor = "wait";
    setTimeout(() => {
      let signatureImageElement = document.getElementById("signature-field");
      if (signatureImageElement) {
        signatureImageElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 500);
    return {
      size: action.size,
      translate: action.translate,
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: state.scale,
      opacity: action.opacity,
      signaturePage: action.signaturePage,
    };
  }
  if (action.type === "ON_REMOVE") {
    return {
      size: [150 * state.scale, 75 * state.scale],
      translate: [100 * state.scale, 100 * state.scale],
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: state.scale,
      opacity: state.opacity,
      signaturePage: state.signaturePage,
    };
  }
  if (action.type === "ON_CHANGE_OPACITY") {
    return {
      size: state.size,
      translate: state.translate,
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: state.scale,
      opacity: action.opacity,
      signaturePage: state.signaturePage,
    };
  }
  if (action.type === "ON_PAGE_CHANGE") {
    return {
      size: state.size,
      translate: state.translate,
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: state.scale,
      opacity: state.opacity,
      signaturePage: action.signaturePage,
    };
  }
  return {
    size: [150, 75],
    translate: [226, 373],
    rotate: 0,
    transformOrigin: "50% 50%",
    scale: 1,
    opacity: "100%",
    signaturePage: 1,
  };
};

const Signer = () => {
  //signature parameters & tooltip context
  const signatureParamsCtx = useContext(SignatureParametersContext);
  const tooltipCtx = useContext(IntroTooltipContext);

  //initial variables and state
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobileDefaultZoomLevel = width <= 980;
  const isMobile = width <= 622;
  const [signingDrawerState, setSigningDrawerState] = useState({
    open: false,
    startingPage: 0,
    signingWithImage: false,
    addNewProfile: false,
    signWithImageContinue: false,
    signingImageProfile: {},
    restartVideoIdentDrawer: false,
    createdSignatureImage: undefined,
  });
  const [signatureFrame, setSignatureFrame] = useReducer(
    signatureFrameReducer,
    {
      size: [
        150 * (isMobileDefaultZoomLevel ? 0.5 : 1.5),
        75 * (isMobileDefaultZoomLevel ? 0.5 : 1.5),
      ],
      translate: [
        100 * (isMobileDefaultZoomLevel ? 0.5 : 1.5),
        100 * (isMobileDefaultZoomLevel ? 0.5 : 1.5),
      ],
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: isMobileDefaultZoomLevel ? 0.5 : 1.5,
      opacity: "100%",
      signaturePage: 1,
    }
  );
  const [moveableTarget, setMoveableTarget] = useState<HTMLElement | undefined>(
    undefined
  );
  const [addSignature, setAddSignature] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [errorStatePdf, setErrorStatePdf] = useState(false);
  const [signWithoutImageModal, setSignWithoutImageModal] = useState({
    open: false,
    rememberChoice: false,
  });
  const [userHitTheBottom, setUserHitTheBottom] = useState(false);

  //pdf viewer instances
  const getFilePluginInstance = getFilePlugin({});
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  //variables
  const signatureImageToSignWithData = useRef<object>({});
  const page = useRef<number>(0);
  const moveableRef = useRef<Moveable>(null);
  const sigCanvas = useRef({});

  //icon for spinner
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 48, color: "white" }} spin />
  );

  //getting signature page element, and setting bounds for sig image
  let signaturePageElement = document.getElementById("signature-page");
  let bounds = signaturePageElement?.getBoundingClientRect();

  //getting info of window width
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  //effect for initial checks
  useEffect(() => {
    //if pdf file is not defined, set error state and dont show toolbar
    if (signatureParamsCtx.pdfFile === undefined) {
      setErrorStatePdf(true);
    } else {
      if (signatureParamsCtx.firstName && signatureParamsCtx.lastName) {
        document.fonts.load("10vmin Caveat").then(() => {
          let canvas = document.createElement("canvas");
          canvas.width = 600;
          canvas.height = 300;
          let ctx = canvas.getContext("2d");
          ctx!.textAlign = "center";
          ctx!.font = "10vmin Caveat";
          ctx!.fillText(
            signatureParamsCtx.firstName + " " + signatureParamsCtx.lastName,
            300,
            150
          );
          let signatureImage = document.createElement("img");
          signatureImage.src = canvas.toDataURL();
          signatureParamsCtx.signatureImageBase64Data = splitBase64(
            signatureImage.src
          );
        });
      }

      //check if we need to show tooltips
      if (enableTooltipComponent) {
        if (localStorage.getItem("eSignLite_ShowTooltips")) {
          if (localStorage.getItem("eSignLite_ShowTooltips") === "true") {
            tooltipCtx.setShowTooltips(true);
            tooltipCtx.setTooltipStep(1);
            tooltipCtx.setShowIntroModal(false);
          } else {
            tooltipCtx.setShowTooltips(false);
            tooltipCtx.setTooltipStep(0);
            tooltipCtx.setShowIntroModal(false);
          }
        } else {
          setTimeout(() => {
            tooltipCtx.setShowIntroModal(true);
          }, 1500);
        }
      }
    }

    //check for restart video identification state
    if (
      signatureParamsCtx.targetIdentUrl !== null &&
      signatureParamsCtx.targetIdentUrl !== undefined
    ) {
      setSigningDrawerState((prevState) => ({
        ...prevState,
        restartVideoIdentDrawer: true,
      }));
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  //const handlers
  //handler for warn about signing without image checkbox
  const handleRememberChoiceCheckbox = useCallback((e: CheckboxChangeEvent) => {
    setSignWithoutImageModal((prevState) => ({
      ...prevState,
      rememberChoice: e.target.checked,
    }));

    localStorage.setItem(
      "eSignLiteSignatureImageWarning",
      e.target.checked.toString()
    );
  }, []);

  //handler for showing modal with warning about signature image - for experian scenario this is not needed, going straight to signing
  const showSignWithoutImageModal = useCallback(() => {
    signingDrawerWithoutImageStateHandler();
  }, []);

  const closeSignWithoutImageModal = useCallback(() => {
    setSignWithoutImageModal((prevState) => ({
      ...prevState,
      open: false,
      rememberChoice: false,
    }));
  }, []);

  const signWithCreatedSignatureImageHandler = (
    data: any,
    createdImage: any
  ) => {
    signatureImageToSignWithData.current = data;
    addSigImageInitial();

    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: false,
      startingPage: 0,
      signingWithImage: false,
      addNewProfile: false,
      createdSignatureImage: createdImage,
    }));
  };

  const signWithImage = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: true,
      startingPage: 2,
      signingImageProfile: signatureImageToSignWithData.current,
      signWithImageContinue: true,
    }));
  };

  const signingDrawerWithoutImageStateHandler = () => {
    if (isMobile) {
      localStorage.setObj("eSignLiteDefaultCTA", "1");
    }

    tooltipCtx.setTooltipStep(3);

    //checking if Direct Sign option is enabled - if it is, wizard is going straight to the last step of the wizard (confirmation)
    if (signatureParamsCtx.directSignOption) {
      setSigningDrawerState((prevState) => ({
        ...prevState,
        open: !signingDrawerState.open,
        startingPage: 2,
      }));
    } else {
      setSigningDrawerState((prevState) => ({
        ...prevState,
        open: !signingDrawerState.open,
        startingPage: 1,
      }));
    }
  };

  const signingDrawerWithImageStateHandler = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: !signingDrawerState.open,
      startingPage: 0,
      signingWithImage: true,
    }));

    tooltipCtx.setTooltipStep(2);
  };

  const closeSigningDrawer = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: false,
      startingPage: 0,
      signingWithImage: false,
      addNewProfile: false,
      signWithImageContinue: false,
      signingImageProfile: {},
    }));

    tooltipCtx.setTooltipStep(1);
  };

  const handleDocumentLoad = async (e: DocumentLoadEvent) => {
    let base64Pdf = await base64_arraybuffer(e.file.data);
    signatureParamsCtx.pdfFileBase64 = base64Pdf;
    signatureParamsCtx.pageForSignaturePosition = e.doc.numPages;

    setTimeout(() => {
      setLoadingState(false);
    }, 500);

    setTimeout(() => {
      let getSignaturePageHtmlElement =
        document.getElementById("signature-page");
      if (getSignaturePageHtmlElement) {
        setSignatureFrame({
          type: "ON_LOAD_TRANSLATE",
          translate: [
            getSignaturePageHtmlElement.getBoundingClientRect().width / 2 -
              signatureFrame.size[0] / 2,
            getSignaturePageHtmlElement.getBoundingClientRect().height / 2 -
              signatureFrame.size[1] / 2,
          ],
        });
      }
    }, 1000);
  };

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <>
      <Toolbar>
        {(slots: ToolbarSlot) => {
          return (
            <PdfViewerTopToolbar
              slots={slots}
              addSignature={addSignature}
              isMobile={isMobile}
            />
          );
        }}
      </Toolbar>
    </>
  );

  const renderError = (error: LoadError) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        new ErrorModalService(13).showErrorModal();
        message = "The document is invalid or corrupted";
        setErrorStatePdf(true);
        break;
      case "MissingPDFException":
        message = "The document is missing";
        setErrorStatePdf(true);
        break;
      case "UnexpectedResponseException":
        setErrorStatePdf(true);
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        setErrorStatePdf(true);
        break;
    }

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          minHeight: "250px",
        }}
      >
        <div
          style={{
            backgroundColor: "#e53e3e",
            borderRadius: "0.25rem",
            color: "#fff",
            padding: "0.5rem",
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [],
  });

  const zoom = (e: ZoomEvent) => {
    setSignatureFrame({ type: "ON_ZOOM", scale: e.scale, opacity: 0 });
    setTimeout(() => {
      setSignatureFrame({ type: "ON_CHANGE_OPACITY", opacity: 1 });
    }, 500);
  };

  const renderPage: RenderPage = (props: RenderPageProps) => {
    if (props.pageIndex === signatureFrame.signaturePage - 1) {
      return (
        <div id="signature-page" style={{ width: "100%", height: "100%" }}>
          {props.canvasLayer.children}
          {addSignature && (
            <div style={{ opacity: signatureFrame.opacity }}>
              <SignContextMenu
                signWithImage={signWithImage}
                removeSigHandler={removeSignatureHandler}
              >
                <img
                  id="signature-field"
                  className={styles.Signator_SignatureFieldContainer}
                  src={
                    signingDrawerState.createdSignatureImage ??
                    SigImageTemplateEN
                  }
                  style={{
                    height: `${signatureFrame.size[1]}px`,
                    width: `${signatureFrame.size[0]}px`,
                    transform: `translate(${signatureFrame.translate[0]}px, ${signatureFrame.translate[1]}px)`,
                    zIndex: "1",
                    position: "relative",
                  }}
                ></img>
              </SignContextMenu>
              <SignatureMoveable
                addSignature={addSignature}
                signatureFrame={signatureFrame}
                removeSignatureHandler={removeSignatureHandler}
                width={width}
                setSignatureFrame={setSignatureFrame}
                renderProps={props}
              />
            </div>
          )}
          <div style={{ display: "none" }}>
            {props.annotationLayer.children}
            {props.textLayer.children}
          </div>
        </div>
      );
    } else {
      return (
        <>
          {props.canvasLayer.children}
          <div style={{ display: "none" }}>
            {props.annotationLayer.children}
            {props.textLayer.children}
          </div>
        </>
      );
    }
  };

  const changePageHandler = (e: any) => {
    page.current = e.currentPage;
    //fix for a bug where on a multipage doc signature field would lose it's connection to moveable, because page is not rendered (that's how PDF viewer works)
    let signatureFieldElement = document.getElementById("signature-field");
    if (
      signatureFieldElement &&
      e.currentPage === signatureFrame.signaturePage - 1
    ) {
      setTimeout(() => {
        setMoveableTarget(document.getElementById("signature-field")!);
      }, 200);
    }

    if (e.currentPage + 1 === e.doc._pdfInfo.numPages && !userHitTheBottom) {
      setUserHitTheBottom(true);
    }
  };

  //signature image function which sets correct offset on Y axis
  function getOffsetY() {
    if (bounds?.height) {
      let boundsHeight = bounds?.height / signatureFrame.scale;
      let imageOffsetY =
        parseInt(signatureFrame.translate[1]) / signatureFrame.scale;
      let imageHeight = signatureFrame.size[1] / signatureFrame.scale;
      return boundsHeight - imageOffsetY - imageHeight;
    } else {
      return 0;
    }
  }

  const addSigImageInitial = () => {
    if (!addSignature) {
      setSignatureFrame({
        type: "ON_PAGE_CHANGE",
        signaturePage: page.current + 1,
      });
      setAddSignature(true);

      tooltipCtx.setTooltipStep(5);

      setTimeout(() => {
        let signatureImageElement = document.getElementById("signature-field");
        if (signatureImageElement) {
          signatureImageElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 500);
    }

    if (addSignature) {
      setTimeout(() => {
        setSignatureFrame({
          type: "ON_PAGE_CHANGE",
          signaturePage: page.current + 1,
        });
        setAddSignature(true);
        setTimeout(() => {
          let signatureImageElement =
            document.getElementById("signature-field");
          if (signatureImageElement) {
            signatureImageElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 500);
      }, 200);
    }
  };

  const handleHideTooltipButton = () => {
    tooltipCtx.setTooltipStep(tooltipCtx.currentTooltipStep! + 1);
  };

  const removeSignatureHandler = () => {
    moveableRef.current?.destroy();
    setAddSignature(false);
    setSignatureFrame({ type: "ON_REMOVE" });
    tooltipCtx.setTooltipStep(1);
  };

  return (
    <div className={styles.PdfSignerOverlay}>
      {signingDrawerState.restartVideoIdentDrawer && (
        <RestartVideoIdent
          restartVideoIdentDrawer={signingDrawerState.restartVideoIdentDrawer}
          closeRestartVideoIdentDrawer={() => {
            setSigningDrawerState((prevState) => ({
              ...prevState,
              restartVideoIdentDrawer: false,
            }));
            signatureParamsCtx.targetIdentUrl = undefined;
          }}
        />
      )}
      <SigningWizard
        signingDrawerOpen={signingDrawerState.open}
        signingWithSignatureImage={signingDrawerState.signingWithImage}
        closeSigningDrawer={closeSigningDrawer}
        startingStep={signingDrawerState.startingPage}
        removeSignatureHandler={removeSignatureHandler}
        signWithCreatedSignatureImageHandler={
          signWithCreatedSignatureImageHandler
        }
        signWithImageContinue={signingDrawerState.signWithImageContinue}
        signingImageProfile={signatureImageToSignWithData.current ?? undefined}
        signatureImage={
          addSignature
            ? {
                page: signatureFrame.signaturePage - 1,
                offsetX: signatureFrame.translate[0] / signatureFrame.scale,
                offsetY: getOffsetY(),
                width: signatureFrame.size[0] / signatureFrame.scale,
                height: signatureFrame.size[1] / signatureFrame.scale,
              }
            : undefined
        }
      />
      <div className={styles.PdfSignerViewer}>
        <>
          <Worker workerUrl={pdfjsWorker}>
            <div
              className="rpv-core__viewer"
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                position: "relative",
              }}
            >
              {!errorStatePdf &&
                !isMobile &&
                !loadingState &&
                !signatureParamsCtx.documentHasBeenSigned && (
                  <IntroTooltip
                    tooltipTextIndex={1}
                    open={
                      tooltipCtx.showTooltips === true &&
                      tooltipCtx.currentTooltipStep === 1
                    }
                    placement={"top"}
                    hideTooltip={handleHideTooltipButton}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        backgroundColor: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        borderRadius: "12px",
                        bottom: "5px",
                        display: "flex",
                        left: "50%",
                        height: "65px",
                        padding: "4px",
                        position: "absolute",
                        transform: "translate(-50%, 0)",
                        zIndex: 10000,
                      }}
                      className={
                        tooltipCtx.showTooltips === true &&
                        tooltipCtx.currentTooltipStep === 1
                          ? styles.SignatorPdfToolbarAnimation
                          : styles.SignatorPdfToolbar
                      }
                    >
                      <div className={styles.SignatorPdfToolbarLeft}>
                        <div
                          style={{
                            marginRight: "20px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <SignButtonMenu
                            signingDrawerWithoutImageStateHandler={
                              signingDrawerWithoutImageStateHandler
                            }
                            signWithoutImageModalHandler={
                              showSignWithoutImageModal
                            }
                            signWithImageHandler={
                              signingDrawerWithImageStateHandler
                            }
                            signWithImage={signWithImage}
                            addSigImageInitial={addSigImageInitial}
                            isSigAdded={addSignature}
                            isMobile={isMobile}
                            userHitTheBottom={userHitTheBottom}
                          />
                        </div>
                      </div>
                      {
                        <div className={styles.SignatorPdfToolbarRight}>
                          <RejectButton
                            showRejectHandler={() =>
                              rejectInfo(signatureParamsCtx.storageId ?? "")
                            }
                            isMobile={isMobile}
                          />
                        </div>
                      }
                    </div>
                  </IntroTooltip>
                )}
              {!errorStatePdf &&
                isMobile &&
                !loadingState &&
                !signatureParamsCtx.documentHasBeenSigned && (
                  <IntroTooltip
                    tooltipTextIndex={1}
                    open={
                      tooltipCtx.showTooltips === true &&
                      tooltipCtx.currentTooltipStep === 1
                    }
                    placement={"top"}
                    hideTooltip={handleHideTooltipButton}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        backgroundColor: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        boxShadow:
                          "black 0px 6px 12px 5px, black 0px 3px 7px -3px",
                        bottom: "0px",
                        display: "flex",
                        left: "50%",
                        height: "65px",
                        width: "100%",
                        padding: "4px",
                        position: "absolute",
                        transform: "translate(-50%, 0)",
                        zIndex: 10000,
                        marginBottom: "0px",
                      }}
                      className={styles.SignatorPdfToolbar}
                    >
                      <div className={styles.SignatorPdfToolbarLeft}>
                        <div
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <SignButtonMenu
                            signingDrawerWithoutImageStateHandler={
                              signingDrawerWithoutImageStateHandler
                            }
                            signWithoutImageModalHandler={
                              showSignWithoutImageModal
                            }
                            signWithImageHandler={
                              signingDrawerWithImageStateHandler
                            }
                            signWithImage={signWithImage}
                            addSigImageInitial={addSigImageInitial}
                            isSigAdded={addSignature}
                            isMobile={isMobile}
                            userHitTheBottom={userHitTheBottom}
                          />
                        </div>
                      </div>
                      <div className={styles.SignatorPdfToolbarRight}>
                        <RejectButton
                          showRejectHandler={() =>
                            rejectInfo(signatureParamsCtx.storageId ?? "")
                          }
                          isMobile={isMobile}
                        />
                      </div>
                    </div>
                  </IntroTooltip>
                )}
              <Spin
                style={{
                  marginTop: "250px",
                  width: "100%",
                  display: loadingState ? "flex" : "none",
                  justifyContent: "center",
                }}
                indicator={antIcon}
              />
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  visibility: loadingState ? "hidden" : "visible",
                }}
              >
                <Viewer
                  fileUrl={signatureParamsCtx.pdfFile ?? DefaultPdf}
                  plugins={[
                    defaultLayoutPluginInstance,
                    getFilePluginInstance as Plugin,
                  ]}
                  theme={{ theme: "light" }}
                  defaultScale={
                    isMobileDefaultZoomLevel ? SpecialZoomLevel.PageFit : 1.5
                  }
                  onPageChange={changePageHandler}
                  onDocumentLoad={handleDocumentLoad}
                  renderPage={renderPage}
                  renderError={renderError}
                  onZoom={zoom}
                ></Viewer>
              </div>
            </div>
          </Worker>
          <Modal
            visible={signWithoutImageModal.open}
            title={i18next.t("signature_image_title")}
            footer={null}
            onCancel={closeSignWithoutImageModal}
          >
            <div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "OpenSans",
                  marginBottom: "5px",
                }}
              >
                {i18next.t("no_sig_image_info_one")}
              </div>
              <div
                style={{
                  fontSize: "15px",
                  textAlign: "center",
                  fontFamily: "OpenSans",
                }}
              >
                {i18next.t("no_sig_image_info_two")}
                <b
                  style={{ color: "rgb(98, 61, 145)", cursor: "pointer" }}
                  onClick={() => {
                    closeSignWithoutImageModal();
                    signingDrawerWithImageStateHandler();
                  }}
                >
                  {i18next.t("no_sig_image_info_three")}
                </b>{" "}
                {i18next.t("no_sig_image_info_four")}
              </div>
              <div className={styles.Signator_SignWithoutImageModalButtons}>
                <div
                  className={styles.Signator_SignWithoutImageModalButtonOne}
                  onClick={() => {
                    if (signWithoutImageModal.rememberChoice === true) {
                      localStorage.setItem(
                        "eSignLiteSignatureImageWarning",
                        "true"
                      );
                    }
                    closeSignWithoutImageModal();
                    signingDrawerWithoutImageStateHandler();
                  }}
                >
                  {i18next.t("continue_without_img")}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Checkbox
                  onChange={handleRememberChoiceCheckbox}
                  checked={signWithoutImageModal.rememberChoice}
                >
                  {i18next.t("dont_ask_again")}
                </Checkbox>
              </div>
            </div>
          </Modal>
          <IntroModal initialStep={1} />
        </>
      </div>
    </div>
  );
};

export default Signer;
