import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import ApprovalIcon from "@mui/icons-material/Approval";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./SignContextMenu.module.css";
import i18next from "i18next";

function SignContextMenu(props: any) {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleSign = () => {
    props.signWithImage();
    setContextMenu(null);
  };

  const handleDelete = () => {
    props.removeSigHandler();
    setContextMenu(null);
  };

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: "context-menu" }}>
      {props.children}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleSign} className={styles.SignContextButton}>
          <ApprovalIcon style={{ marginRight: "4px" }} /> {i18next.t("btn_sign")}
        </MenuItem>
        <MenuItem onClick={handleDelete} className={styles.DeleteContextButton}>
          <DeleteIcon style={{ marginRight: "4px" }} /> {i18next.t("delete_sig_img")}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default SignContextMenu;
