import {
  CloudUploadOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import i18next from "i18next";

const LandingCards = (props: any) => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      style={{
        marginTop: "2em",
        marginBottom: "5em",
        paddingLeft: "5em",
        paddingRight: "5em",
        width: "100%",
      }}
    >
      {/*<Grid item xs={12} sm={6} md={6} lg={3}>
        <Card style={{minWidth:"15em", height:"13em", backgroundColor:"#F5F5F5", maxWidth:"25em"}}>
        <CardContent>
          <Typography sx={{ fontSize: "0.8em", fontWeight: "bold"}} color="#623D91" gutterBottom>
            {i18next.t("step_1")}
          </Typography>
          <CloudUploadOutlined style={{ display:"flex", fontSize: '6em', color: '#623D91', alignItems:"center", justifyContent:"center" }} />
          <br/>
          <Typography sx={{ fontSize: "0.8em", textAlign: "center"}} color="#623D91">
            {i18next.t("step_1_helptext")}
          </Typography>
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12} sm={6} md={6} lg={3}>
        <Card style={{minWidth:"15em", height:"13em", backgroundColor:"#F5F5F5", maxWidth:"25em"}}>
        <CardContent>
          <Typography sx={{ fontSize: "0.8em", fontWeight: "bold"}} color="#623D91" gutterBottom>
          {i18next.t("step_2")}
          </Typography>
          <EditOutlined style={{ display:"flex", fontSize: '6em', color: '#623D91', alignItems:"center", justifyContent:"center" }}/>
          <br/>
          <Typography sx={{ fontSize: "0.8em", textAlign: "center"}} color="#623D91">
          {i18next.t("step_2_helptext")}
          </Typography>
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12} sm={6} md={6} lg={3}>
        <Card style={{minWidth:"15em", height:"13em", backgroundColor:"#F5F5F5", maxWidth:"25em"}}>
        <CardContent>
          <Typography sx={{ fontSize: "0.8em", fontWeight: "bold"}} color="#623D91" gutterBottom>
          {i18next.t("step_3")}
          </Typography>
          <FileTextOutlined style={{ display:"flex", fontSize: '6em', color: '#623D91', alignItems:"center", justifyContent:"center" }}/>
          <br/>
          <Typography sx={{ fontSize: "0.8em", textAlign: "center"}} color="#623D91">
          {i18next.t("step_3_helptext")}
          </Typography>
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12} sm={6} md={6} lg={3}>
        <Card style={{minWidth:"15em", height:"13em", backgroundColor:"#F5F5F5", maxWidth:"25em"}}>
        <CardContent>
          <Typography sx={{ fontSize: "0.8em", fontWeight: "bold"}} color="#623D91" gutterBottom>
          {i18next.t("step_4")}
          </Typography>
          <DownloadOutlined style={{ display:"flex", fontSize: '6em', color: '#623D91', alignItems:"center", justifyContent:"center" }}/>
          <br/>
          <Typography sx={{ fontSize: "0.8em", textAlign: "center"}} color="#623D91">
          {i18next.t("step_4_helptext")}
          </Typography>
        </CardContent>
      </Card>
    </Grid>*/}
    </Grid>
  );
};
export default LandingCards;
