import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Spin } from "antd";

import { ENABLED_LANGUAGES } from "./appsettings";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "flag-icon-css/css/flag-icon.min.css";
import "antd/dist/antd.min.css";
import { SignatureProvider } from "./components/SigningComponent/SigningComponent";
import SignatureParametersContext from "./store/SignatureParametersContext";
import { HashRouter } from "react-router-dom";
import {ErrorBoundary} from "./components/ErrorHandling/ErrorBoundary";

const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
const urlLanguage = urlParams.get("lang")?.toLowerCase() ?? "en";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ENABLED_LANGUAGES,
    fallbackLng: "en",

    detection: {
      order: ["path", "cookie", "querystring", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "locales/{{lng}}/translation.json",
    },
  });

i18n.changeLanguage(urlLanguage);

Storage.prototype.setObj = function (key: any, obj: any) {
  return this.setItem(key, JSON.stringify(obj));
};

Storage.prototype.getObj = function (key: any, obj: any) {
  if (this.getItem(key) === "[null]") {
    this.removeItem("SignatorLightSignatureProfiles");
    return;
  }
  try {
    if (this.getItem(key)) return JSON.parse(this.getItem(key)!);
  } catch (e) {
    console.log(e);
    this.removeItem("SignatorLightSignatureProfiles");
  }
};

const loadingPage = (
  <div className="py-4 text-center">
    <Spin size="large" style={{ marginTop: "150px" }} />
  </div>
);

ReactDOM.render(
  <SignatureParametersContext.Provider
    value={{
      predefinedSignatureOptions: false,
      directSignOption: true,
      signatureToken: undefined,
      pdfFile: undefined,
      pdfFileBase64: undefined,
      phoneNumber: undefined,
      jurisdiction: undefined,
      signatureLevel: undefined,
      prepaid: undefined,
      storageId: undefined,
      targetIdentUrl: undefined,
      firstName: undefined,
      lastName: undefined,
      signatureProvider: SignatureProvider.SwisscomAis,
      signatureImageBase64Data: undefined,
      pageForSignaturePosition: 0,
      documentHasBeenSigned: false,
      signaturePosition: null,
    }}
  >
    <HashRouter>
    <ErrorBoundary>
      <Suspense fallback={loadingPage}>
        <App />
      </Suspense>
    </ErrorBoundary>
    </HashRouter>
  </SignatureParametersContext.Provider>,
  document.getElementById("root")
);
