import React from "react";
import { enableTooltipComponent } from "../appsettings";

interface IIntroTooltipContext {
  introTooltipsModalState: boolean | undefined;
  showTooltips: boolean | undefined;
  currentTooltipStep: number | undefined;
  showSettingsTooltip: boolean | undefined;
  setShowSettingsTooltip: Function;
  setTooltipStep: Function;
  setShowTooltips: Function;
  setShowIntroModal: Function;
}

const IntroTooltipContext = React.createContext<IIntroTooltipContext>({
  introTooltipsModalState: enableTooltipComponent,
  showTooltips: false,
  currentTooltipStep: undefined,
  showSettingsTooltip: false,
  setShowSettingsTooltip: (showSettingsTooltip: any) => {},
  setTooltipStep: (step: number) => {},
  setShowTooltips: (showTooltip: any) => {},
  setShowIntroModal: (showIntro: any) => {},
});

export default IntroTooltipContext;
