import styles from "./App.module.css";
import { useNavigate } from "react-router-dom";
import Router from "./pages/Router";
import CookieConsent from "react-cookie-consent";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import SignatureParametersContext, {
  SignaturePosition,
} from "./store/SignatureParametersContext";
import axios from "axios";
import { BASE_URL } from "./appsettings";
import { ErrorModalService } from "./components/ErrorHandling/ErrorModalService";
import { Spin } from "antd";
import IntroTooltipContext from "./store/IntroTooltipContext";

function App() {
  const [loadingFile, setLoadingFile] = useState(false);
  const [tooltipStep, setTooltipStep] = useState(0);
  const [showTooltips, setShowTooltips] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showSettingsTooltip, setShowSettingsTooltip] = useState(false);

  const { t } = useTranslation();

  const navigation = useNavigate();

  let search = window.location.search;
  let params = new URLSearchParams(search.split("?")[1]);

  //signature parameters context
  const signatureParamsCtx = useContext(SignatureParametersContext);

  useEffect(() => {
    signatureParamsCtx.signatureLevel = params.get("sig_level");
    signatureParamsCtx.signatureToken = params.get("signature_token");
    signatureParamsCtx.jurisdiction = params.get("jurisdiction");
    signatureParamsCtx.targetIdentUrl = params.get("target_url");
    signatureParamsCtx.prepaid = params.get("prepaid");
    signatureParamsCtx.phoneNumber = params.get("phone_number");
    signatureParamsCtx.storageId = params.get("storage_id");
    signatureParamsCtx.firstName = params.get("first_name");
    signatureParamsCtx.lastName = params.get("last_name");
    
    if(params.get("sig_pos") === "phase_one" || params.get("sig_pos") === "phase_two"){
      signatureParamsCtx.signaturePosition = params.get("sig_pos") as SignaturePosition;
    }

    if (
      params.get("storage_id") &&
      params.get("sig_level") &&
      params.get("jurisdiction") &&
      params.get("phone_number") &&
      params.get("first_name") &&
      params.get("last_name")
    ) {
      setLoadingFile(true);

      signatureParamsCtx.directSignOption = true;

      axios
        .get(
          BASE_URL + `/v/1/signer/document/storage/${params.get("storage_id")}`,
          {
            responseType: "blob",
          }
        )
        .then(
          async (response) => {
            let convertPdf = await toUint8Array(response.data);
            signatureParamsCtx.pdfFile = convertPdf;
            signatureParamsCtx.predefinedSignatureOptions = true;
            setLoadingFile(false);
            navigation("/sign");
          },
          async (reason: any) => {
            if (reason && reason.message === "Network Error") {
              setLoadingFile(false);
              new ErrorModalService(100).showErrorModal();
            } else {
              const errorText = await reason.response.data.text();
              try {
                let parsedErrorText = JSON.parse(errorText);
                if (
                    parsedErrorText &&
                    parsedErrorText.OperationData.ErrorCode === 24101
                ) {
                  new ErrorModalService(2).showErrorModal();
                  setLoadingFile(false);
                  return;
                }
              } catch (e) {
                setLoadingFile(false);
                new ErrorModalService(100).showErrorModal();
                return;
              }
              setLoadingFile(false);
              new ErrorModalService(100).showErrorModal();
            }
          }
        )
        .catch((e) => console.log(e));

      return;
    } else {
      new ErrorModalService(31).showErrorModal();
    }
  }, [params.get("storage_id")]);

  const toUint8Array = async (file: File) => {
    let tempFile = file as Blob;
    let convertedFile = await tempFile.arrayBuffer();
    return new Uint8Array(convertedFile);
  };

  if (loadingFile) {
    return (
      <>
        <div className={styles.AppLoadingElement}>
          <Spin size="large" />
        </div>
      </>
    );
  }

  return (
    <IntroTooltipContext.Provider
      value={{
        introTooltipsModalState: showIntroModal,
        showTooltips: showTooltips,
        currentTooltipStep: tooltipStep,
        showSettingsTooltip: showSettingsTooltip,
        setShowSettingsTooltip: setShowSettingsTooltip,
        setTooltipStep: setTooltipStep,
        setShowTooltips: setShowTooltips,
        setShowIntroModal: setShowIntroModal,
      }}
    >
      <div className={styles.Overlay}>
        <div className={styles.App} id="signator_light_app_element">
          <Router />
        </div>
        <CookieConsent
          buttonStyle={{ marginRight: "85px", width: "80px" }}
          style={{ alignItems: "center" }}
          buttonText="OK"
        >
          {i18next.t("cookie_consent_msg_1")}
          <a
            href="https://www.google.bg/#q=how+to+disable+cookies"
            target={"_blank"}
            style={{ color: "white" }}
            rel={"noreferrer"}
          >
            {i18next.t("cookie_consent_msg_2")}
          </a>
        </CookieConsent>
      </div>
    </IntroTooltipContext.Provider>
  );
}

export default App;
