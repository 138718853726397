import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1cbeda",
    color: "white",
    boxShadow: theme.shadows[1],
    fontFamily: "OpenSans",
    fontWeight: "bold",
    fontSize: 11,
    animation: "fadeInAnimation ease 2s",
  },
}));

const IntroTooltip = (props: any) => {
  let tooltipTextIndex = "";

  if (props.tooltipTextIndex === 0) {
    tooltipTextIndex = "Click here to upload the PDF document you want to sign";
  }
  if (props.tooltipTextIndex === 1) {
    tooltipTextIndex =
      "Choose “Sign” if you wish to sign the document without the signature image, or “Sig. image” to create signature image and define parameters.";
  }
  if (props.tooltipTextIndex === 2) {
    tooltipTextIndex =
      "Choose a predefined signature profile or create new by clicking on a 'Add new signature' button";
  }
  if (props.tooltipTextIndex === 3) {
    tooltipTextIndex =
      "Choose a predefined signature profile or create new by clicking on a 'Add new signature' button";
  }
  if (props.tooltipTextIndex === 4) {
    tooltipTextIndex =
      "Choose signature parameters, and when done, click “Confirm”";
  }
  if (props.tooltipTextIndex === 5) {
    tooltipTextIndex =
      "Position your signature image to desired location. When done, click “Sign”.";
  }
  if (props.tooltipTextIndex === 6) {
    tooltipTextIndex =
      "Choose a predefined signature profile or create new by clicking on a 'Add new signature' button";
  }
  if (props.tooltipTextIndex === 7) {
    tooltipTextIndex =
      "Write, draw or upload your signature image, enter your personal information and click on 'Next' button";
  }

  return (
    <CustomTooltip
      open={props.open}
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div id="close" onClick={props.hideTooltip}>
            <span style={{ paddingBottom: "2px" }}>x</span>
          </div>
          <div style={{ textAlign: "center", padding: "2px" }}>
            {tooltipTextIndex}
          </div>
        </div>
      }
      arrow
      placement={props.placement}
    >
      {props.children}
    </CustomTooltip>
  );
};

export default IntroTooltip;
