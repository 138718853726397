import React from "react";
import Error from "../images/error.jpg";
import styles from "./LandingPage.module.css";

export default function ErrorPage() {
  return (
    <div>
      <img src={Error} className={styles.errorImg} />
      <div>
        <h2 className={styles.errorTitle}>{"Ooops! Something went wrong."}</h2>
      </div>
    </div>
  );
}
