import { Modal } from "antd";
import styles from "../../pages/LandingPage.module.css";
import i18next from "i18next";

enum ModalTypes {
  ERROR = "ERROR",
  INFO = "INFO",
  TRY_AGAIN = "TRY_AGAIN"
}

const errorModal = (title: string, message: string) => {
  Modal.error({
    title: (
        <div>
          {i18next.t("error_title")}
          <hr />
        </div>
    ),
    content: (
        <div>
          <p>{message}</p>
        </div>
    ),
    closable: true,
    okButtonProps: { type: "default", className: styles.SignatorOkModalButton },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

const tryAgainModal = (title: string, message: string) => {
  Modal.confirm({
    title:  (
        <div>
          {i18next.t("warning_title")}
          <hr />
        </div>
    ),
    content: (
        <div>
          <p>{message}</p>
        </div>
    ),
    onOk(){
      window.location.reload();
    },
    closable: true,
    cancelText: i18next.t('cancel_label_lower'),
    okText: i18next.t('try_again'),
    okButtonProps: { type: "default", className: styles.SignatorOkModalButton },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

const infoModal = (title: string, message: string) => {
  Modal.info({
    title:  (
        <div>
          {i18next.t("information_title")}
          <hr />
        </div>
    ),
    content: (
        <div>
          <p>{message}</p>
        </div>
    ),
    closable: true,
    okButtonProps: { type: "default", className: styles.SignatorOkModalButton },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

export class ErrorModalService {
  private readonly errorCode: number;

  constructor(errorCode: number) {
    this.errorCode = errorCode;
  }

  public showErrorModal() {
    let errorMessage = i18next.t("ident_generic_error");
    let modalHeader = i18next.t('error');
    let modalType = ModalTypes.ERROR;

    if (this.errorCode === 1) {
      errorMessage = i18next.t("ident_generic_error");
    } else if (this.errorCode === 2) {
      errorMessage = i18next.t("storage_id_error");
      modalHeader = i18next.t('storage_error_header');
      modalType = ModalTypes.INFO;
    } else if (this.errorCode === 3) {
      errorMessage = i18next.t("params_error");
    } else if (this.errorCode === 4) {
      errorMessage = i18next.t("toast_warn_pdf");
    } else if (this.errorCode === 5) {
      errorMessage = i18next.t("ident_generic_error");
    } else if (this.errorCode === 6) {
      errorMessage = i18next.t("signatures_check_error");
    } else if (this.errorCode === 7) {
      errorMessage = i18next.t("unsupported_logo_format");
    } else if (this.errorCode === 8) {
      errorMessage = i18next.t("too_big_logo");
    } else if (this.errorCode === 9) {
      errorMessage = i18next.t("sig_image_empty");
    } else if (this.errorCode === 10) {
      errorMessage = i18next.t("name_empty");
    } else if (this.errorCode === 11) {
      errorMessage = i18next.t("sig_image_empty_save");
    } else if (this.errorCode === 13) {
      errorMessage = i18next.t("invalid_document");
    } else if (this.errorCode === 14) {
      errorMessage = i18next.t("unable_to_check_signatures_amount");
    } else if (this.errorCode === 15) {
      errorMessage = i18next.t("multiple_images");
    } else if (this.errorCode === 16) {
      errorMessage = i18next.t("invalid_sig_image");
    } else if (this.errorCode === 17) {
      errorMessage = i18next.t("multiple_images");
    } else if (this.errorCode === 18) {
      errorMessage = i18next.t("adding_sig_image_error");
    } else if (this.errorCode === 21) {
      errorMessage = i18next.t("sig_provider_not_available");
    } else if (this.errorCode === 22) {
      errorMessage = i18next.t("profile_name_error");
    } else if (this.errorCode === 23) {
      errorMessage = i18next.t("video_ident_start_error");
    } else if (this.errorCode === 24) {
      errorMessage = i18next.t("video_ident_failed");
    } else if (this.errorCode === 25) {
      errorMessage = i18next.t("purchase_error");
    } else if (this.errorCode === 26) {
      errorMessage = i18next.t("purchase_success");
    } else if (this.errorCode === 27) {
      errorMessage = i18next.t("organization_error");
    } else if (this.errorCode === 28) {
      errorMessage = i18next.t("doc_not_available");
    } else if (this.errorCode === 29) {
      errorMessage = i18next.t("saving_profile_size_error");
    } else if (this.errorCode === 30) {
      errorMessage = i18next.t("getting_sig_image_error");
    } else if (this.errorCode === 31) {
      errorMessage = i18next.t("missing_parameters");
    } else if(this.errorCode === 100){
      errorMessage = i18next.t('service_not_available');
      modalHeader = i18next.t('service_not_available_header');
      modalType = ModalTypes.TRY_AGAIN
    }


    if(modalType === ModalTypes.INFO){
      infoModal(modalHeader, errorMessage);
    } else if (modalType === ModalTypes.TRY_AGAIN) {
      tryAgainModal(modalHeader, errorMessage)
    } else {
      errorModal(modalHeader, errorMessage);
    }
  }
}
