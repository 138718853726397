import {AxiosError} from "axios";
import i18next from "i18next";
import {PaymentAndIdentCheckEnabled} from "../../appsettings";

export class AxiosErrorException {
    private readonly error: AxiosError;

    constructor(error: AxiosError) {
        this.error = error;
    }

    public throwError(){
        if(this.error?.response?.status && this.error.response.status === 500){
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error: i18next.t("signator_network_error"),
            });
            return;
        } else if(this.error?.response?.status && this.error.response.status === 404){
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error: i18next.t("signator_network_error"),
            });
            return;
        } else if (this.error?.response?.data?.errors?.[0]?.errorCode === 20007) {
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR_VIDEO_IDENT",
                error: i18next.t("20007_message"),
            });
            return;
        } else if (this.error?.response?.data?.errors?.[0]?.errorCode === 20008) {
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR_VIDEO_IDENT",
                error: i18next.t("20008_message"),
            });
            return;
        } else if (this.error?.response?.data?.errors?.[0]?.errorCode === 24101) {
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error: i18next.t("doc_signed_or_deleted"),
            });
            console.log("ERROR_MESSAGE: ", "DOC SIGNED OR DOESN'T EXIST");
            return;
        }  else if (this.error?.response?.data?.errors?.[0]?.errorCode === 25015) {
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error: "SIGNING_COMBINATION_ERROR",
            });
            console.log("ERROR_MESSAGE: ", "NO CREDITS");
            return;
        } else if (this.error?.response?.data?.errors?.[0]?.errorCode === 25028 && PaymentAndIdentCheckEnabled) {
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error: i18next.t('unable_sign_error'),
            });
            return "ERROR";
        } else if (this.error?.response?.data?.errors?.[0]?.errorCode === 25027 && PaymentAndIdentCheckEnabled) {
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error:
                    "We are unable to process your request. Please contact organization which sent you sign request!",
            });
            console.log("ERROR_MESSAGE");
            return "ERROR";
        } else if (this.error?.response?.data?.errors?.[0]?.errorCode === 25003 && PaymentAndIdentCheckEnabled) {
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error: i18next.t('unable_sign_error'),
            });
            return "ERROR";
        } else if(this.error?.message === "Network Error"){
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error: i18next.t("signator_network_error"),
            });
            return "ERROR";
        } else {
            window.parent.postMessage({
                type: "SIGNATOR_SIGNING_ERROR",
                error: i18next.t('unable_sign_error'),
            });
            console.log("ERROR_MESSAGE", this.error);
            return;
        }
    }
}
