import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "../SigningWizard.module.css";
import { Radio } from "../../../components/Radio";
import {
  Jurisdiction,
  SignatureLevel,
  SignatureProvider,
} from "../../../components/SigningComponent/SigningComponent";
import SwisscomLogo from "../../../images/swisscom.png";
import AESLogo from "../../../images/AES.svg";
import QESLogo from "../../../images/QES.svg";
import SESLogo from "../../../images/SES.svg";
import { Fade } from "react-awesome-reveal";
import { useFormState } from "react-final-form";
import StepperTypeComponent from "../StepperTypeComponent";
import EuFlag from "../../../images/EU.png";
import ChFlag from "../../../images/CH.png";
import { Telephone } from "../../../components/Telephone";
import { Input } from "antd";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  defaultPredefinedLevel,
  defaultPredefinedProvider,
  defaultPredefinedStandard,
  SigningOptionsPredefined,
} from "../../../appsettings";
import { Spin, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import SignatureParametersContext from "../../../store/SignatureParametersContext";
import IntroTooltipContext from "../../../store/IntroTooltipContext";
import IntroTooltip from "../../../components/IntroTooltip/IntroTooltip";
import i18next from "i18next";

enum StepTwoState {
  PROFILES = "PROFILES",
  BASIC = "BASIC",
}

function StepTwo(props: any) {
  const formState = useFormState();

  const signatureParamsCtx = useContext(SignatureParametersContext);
  const tooltipCtx = useContext(IntroTooltipContext);

  let profiles = localStorage.getObj("eSignLiteSignaturesOnly") as Array<any>;
  let profilesImage = localStorage.getObj("eSignLiteSignatures") as Array<any>;
  let acceptedTCStorage = localStorage.getItem("eSignLiteTCAccepted");

  let profilesTotal =
    (profiles ? profiles.length : 0) +
    (profilesImage ? profilesImage.length : 0);

  const [stepTwoState, setStepTwoState] = useState({
    loadingProfiles: false,
    state: StepTwoState.BASIC as StepTwoState,
    showProfiles: false,
    profiles: [] as any[],
    acceptTC: false,
    acceptedTC: false,
  });
  const [subStep, setSubStep] = useState(0);
  const [profileName, setProfileName] = useState(
    profilesTotal > 0 ? "Signature " + (profilesTotal + 1) : "Signature 1"
  );

  //effect for checking and showing profiles from local storage
  useEffect(() => {
    setStepTwoState((prevState) => ({
      ...prevState,
      loadingProfiles: true,
    }));

    setTimeout(() => {
      setStepTwoState((prevState) => ({
        ...prevState,
        loadingProfiles: false,
      }));
    }, 500);

    //check for accepted T&C key in local storage, and set state to it's value
    if (acceptedTCStorage && acceptedTCStorage === "true") {
      setStepTwoState((prevState) => ({
        ...prevState,
        acceptedTC: true,
      }));
    }

    let fetchProfiles = localStorage.getObj(
      "eSignLiteSignaturesOnly"
    ) as Array<any>;

    if (
      !props.addProfileState &&
      fetchProfiles &&
      fetchProfiles.filter((item) => item.txtType === "NO_IMAGE").length > 0 &&
      !signatureParamsCtx.predefinedSignatureOptions
    ) {
      setStepTwoState((prevState) => ({
        ...prevState,
        state: StepTwoState.PROFILES,
        profiles: fetchProfiles,
        showProfiles: true,
      }));
    }

    if (
      !props.addProfileState &&
      fetchProfiles &&
      signatureParamsCtx.predefinedSignatureOptions
    ) {
      let filteredProfiles = fetchProfiles.filter(
        (item) =>
          item.txtType === "NO_IMAGE" &&
          item.txtSignatureLevel === signatureParamsCtx.signatureLevel &&
          item.txtSignatureStandard === signatureParamsCtx.jurisdiction &&
          item.txtSignatureProvider === signatureParamsCtx.signatureProvider
      );

      if (filteredProfiles.length > 0 && !signatureParamsCtx.phoneNumber) {
        setStepTwoState((prevState) => ({
          ...prevState,
          state: StepTwoState.PROFILES,
          profiles: filteredProfiles,
          showProfiles: true,
        }));
      } else {
        setStepTwoState((prevState) => ({
          ...prevState,
          state: StepTwoState.BASIC,
          profiles: [] as any[],
          showProfiles: false,
        }));
      }
    }

    formState.values["txtSigImageProfileName"] = profileName;
  }, [localStorage.getItem("eSignLiteSignaturesOnly")]);

  useEffect(() => {
    if (
      SigningOptionsPredefined === true &&
      !signatureParamsCtx.predefinedSignatureOptions
    ) {
      formState.values["txtSignatureProvider"] = defaultPredefinedProvider;
      formState.values["txtSignatureLevel"] = defaultPredefinedLevel;
      formState.values["txtSignatureStandard"] = defaultPredefinedStandard;
      setSubStep(3);
    }
  }, []);

  //effect for prefilling form values in case sig options are predefined
  useEffect(() => {
    if (
      signatureParamsCtx.predefinedSignatureOptions &&
      signatureParamsCtx.phoneNumber !== null &&
      !stepTwoState.showProfiles
    ) {
      formState.values["txtMobitel"] =
        "+" + signatureParamsCtx.phoneNumber!.trim();
      formState.values["txtSignatureProvider"] =
        signatureParamsCtx.signatureProvider;
      formState.values["txtSignatureLevel"] = signatureParamsCtx.signatureLevel;
      formState.values["txtSignatureStandard"] =
        signatureParamsCtx.jurisdiction;
      setSubStep(3);
      return;
    }

    if (
      signatureParamsCtx.predefinedSignatureOptions &&
      signatureParamsCtx.phoneNumber === null &&
      !stepTwoState.showProfiles
    ) {
      formState.values["txtSignatureProvider"] =
        signatureParamsCtx.signatureProvider;
      formState.values["txtSignatureLevel"] = signatureParamsCtx.signatureLevel;
      formState.values["txtSignatureStandard"] =
        signatureParamsCtx.jurisdiction;
      setSubStep(3);
      return;
    }

    if (formState.values["txtSignatureStandard"] === undefined) {
      setSubStep(0);
    } else if (formState.values["txtSignatureProvider"] === undefined) {
      setSubStep(1);
    } else if (formState.values["txtSignatureLevel"] === undefined) {
      setSubStep(2);
    } else if (
      formState.values["txtMobitel"] === undefined ||
      signatureParamsCtx.phoneNumber
    ) {
      setSubStep(3);
    }
  }, [formState.values]);

  //T&C checkbox handler
  const handleTCCheckbox = (e: CheckboxChangeEvent) => {
    setStepTwoState((prevState) => ({
      ...prevState,
      acceptTC: e.target.checked,
    }));

    formState.values["txtAcceptTC"] = e.target.checked;
  };

  //back button handler -> sets previous substep and clears form value from previous and current step
  const backButtonHandler = () => {
    if (subStep === 3) {
      formState.values["txtSignatureLevel"] = undefined;
      formState.values["txtMobitel"] = undefined;
      setSubStep(2);
    } else if (subStep === 2) {
      formState.values["txtSignatureProvider"] = undefined;
      formState.values["txtSignatureLevel"] = undefined;
      setSubStep(1);
    } else if (subStep === 1) {
      formState.values["txtSignatureStandard"] = undefined;
      formState.values["txtSignatureProvider"] = undefined;
      setSubStep(0);
    } else {
      setSubStep(0);
    }
  };

  //handler for stepper onClick function
  const stepBackButtonHandler = (index: any) => {
    if (index >= subStep) {
      return;
    } else if (
      index === 0 &&
      !signatureParamsCtx.predefinedSignatureOptions &&
      !SigningOptionsPredefined
    ) {
      formState.values["txtSignatureStandard"] = undefined;
      formState.values["txtSignatureProvider"] = undefined;
      formState.values["txtSignatureLevel"] = undefined;
      formState.values["txtMobitel"] = undefined;
      setSubStep(0);
    } else if (
      index === 1 &&
      !signatureParamsCtx.predefinedSignatureOptions &&
      !SigningOptionsPredefined
    ) {
      formState.values["txtSignatureProvider"] = undefined;
      formState.values["txtSignatureLevel"] = undefined;
      formState.values["txtMobitel"] = undefined;
      setSubStep(1);
    } else if (
      index === 2 &&
      !signatureParamsCtx.predefinedSignatureOptions &&
      !SigningOptionsPredefined
    ) {
      formState.values["txtSignatureLevel"] = undefined;
      formState.values["txtMobitel"] = undefined;
      setSubStep(2);
    } else {
      return;
    }
  };

  const removeSavedSignatureProfileHandler = (profileId: any) => {
    let profileToDelete = profiles.findIndex(
      (item) => item.profileId === profileId
    );
    profiles?.splice(profileToDelete, 1);
    if (profiles?.length === 0) {
      localStorage.removeItem("eSignLiteSignaturesOnly");
      setStepTwoState((prevState) => ({
        ...prevState,
        state: StepTwoState.BASIC,
        profiles: [],
      }));
      props.addProfileStateHandler(true);
    } else {
      localStorage.setObj("eSignLiteSignaturesOnly", profiles);
      setStepTwoState((prevState) => ({
        ...prevState,
        profiles: profiles,
      }));
    }
  };

  const handleHideTooltipButton = () => {
    tooltipCtx.setTooltipStep(tooltipCtx.currentTooltipStep! + 1);
  };

  const signWithProfileHandler = (profile: any) => {
    formState.values["txtMobitel"] = profile.txtMobitel ?? "";
    formState.values["txtSignatureLevel"] = profile.txtSignatureLevel ?? "";
    formState.values["txtSignatureProvider"] =
      profile.txtSignatureProvider ?? "";
    formState.values["txtSignatureStandard"] =
      profile.txtSignatureStandard ?? "";

    props.signWithSavedProfile();
  };

  if (
    stepTwoState.state === StepTwoState.PROFILES &&
    stepTwoState.showProfiles
  ) {
    return (
      <Fade>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              alignSelf: "center",
              width: "100%",
              marginTop: "25px",
            }}
          >
            <div className={styles.SignatorStepContainer}>
              <IntroTooltip
                tooltipTextIndex={6}
                open={
                  tooltipCtx.showTooltips === true &&
                  tooltipCtx.currentTooltipStep === 3 &&
                  stepTwoState.loadingProfiles !== true
                }
                placement={"top"}
                hideTooltip={handleHideTooltipButton}
              >
                <div className={styles.SignatorStepTitle}>Select signature</div>
              </IntroTooltip>
            </div>
            {stepTwoState.loadingProfiles ? (
              <Spin
                size="large"
                style={{ marginTop: "150px", width: "100%" }}
              />
            ) : (
              <>
                <div className={styles.SignatorStepContainer}>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {stepTwoState.profiles
                      .filter((item) => item.txtType === "NO_IMAGE")
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "65%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                            className={styles.Signator_SignatureProfileNoImage}
                          >
                            <div
                              style={{
                                marginBottom: "5px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "3px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                {item.txtSigImageProfileName}
                              </span>
                              <span
                                onClick={() =>
                                  removeSavedSignatureProfileHandler(
                                    item.profileId
                                  )
                                }
                              >
                                <DeleteIcon
                                  style={{ color: "red", cursor: "pointer" }}
                                />
                              </span>
                            </div>
                            <div
                              className={styles.SignatorSignatureProfileCard}
                              onClick={() => signWithProfileHandler(item)}
                            >
                              <div
                                className={
                                  styles.SignatorSignatureProfileDetails
                                }
                                style={{ borderTop: "none" }}
                              >
                                <div
                                  className={
                                    styles.SignatorSignatureProfileBasicDetails
                                  }
                                  style={{
                                    height: "35px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.txtMobitel}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={
                                  styles.SignatorSignatureProfileSignatureDetails
                                }
                              >
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    color: "#623d91",
                                  }}
                                  className={
                                    styles.Signator_SignatureProfileSignatureDetails_Text
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "black",
                                    }}
                                  >
                                    {i18next.t("level")}:{" "}
                                  </span>
                                  {item.txtSignatureLevel}
                                </span>
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    color: "#623d91",
                                  }}
                                  className={
                                    styles.Signator_SignatureProfileSignatureDetails_Text
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "black",
                                    }}
                                  >
                                    {i18next.t("provider")}:{" "}
                                  </span>
                                  {item.txtSignatureProvider}
                                </span>
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    color: "#623d91",
                                  }}
                                  className={
                                    styles.Signator_SignatureProfileSignatureDetails_Text
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "black",
                                    }}
                                  >
                                    {i18next.t("standard")}:{" "}
                                  </span>
                                  {item.txtSignatureStandard}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div
                    className={styles.SignatorStepSubtitle}
                    onClick={() => {
                      setStepTwoState((prevState) => ({
                        ...prevState,
                        state: StepTwoState.BASIC,
                      }));
                      props.addProfileStateHandler(true);
                    }}
                  >
                    <AddPhotoAlternateIcon /> Add new signature
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Fade>
    );
  }

  return (
    <Fade>
      <div
        className={styles.SignatorStepContainer}
        style={{ justifyContent: "center", marginTop: "50px" }}
      >
        <div className={styles.SignatorStepTitle}>{i18next.t("define_sig_type")}</div>
      </div>
      <div
        className={styles.Signator_StepTwoContainer}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "300px",
          marginTop: "20px",
        }}
      >
        <StepperTypeComponent
          currentPage={subStep}
          stepBackButtonHandler={stepBackButtonHandler}
        />
        {props.waitingForSignatureImage ? (
          <Spin size="large" style={{ width: "100%" }} />
        ) : (
          <div
            className={styles.Signator_StepTwoBodyContainer}
            style={{
              alignSelf: "center",
              width: "70%",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={styles.SignatorStepContainer}>
              <IntroTooltip
                tooltipTextIndex={4}
                open={
                  tooltipCtx.showTooltips === true &&
                  tooltipCtx.currentTooltipStep === 3
                }
                placement={"top"}
                hideTooltip={handleHideTooltipButton}
              >
                <div className={styles.SignatorStepTitleSmall}>
                  {subStep === 0 && "Select standard"}
                  {subStep === 1 && "Select provider"}
                  {subStep === 2 && "Select legal weight"}
                  {subStep === 3 && "Enter number"}
                </div>
              </IntroTooltip>
              <div>
                {formState.values["txtSignatureStandard"] === undefined && (
                  <Fade>
                    <div className={styles.SignatureStandardRadiosBody}>
                      <Radio
                        label={Jurisdiction.EIDAS}
                        name="txtSignatureStandard"
                        value={Jurisdiction.EIDAS}
                        radioIcon={EuFlag}
                      />
                      <Radio
                        label={Jurisdiction.ZERTES}
                        name="txtSignatureStandard"
                        value={Jurisdiction.ZERTES}
                        radioIcon={ChFlag}
                      />
                    </div>
                  </Fade>
                )}
                {formState.values["txtSignatureStandard"] !== undefined &&
                  formState.values["txtSignatureProvider"] === undefined && (
                    <Fade>
                      <div className={styles.SignatureStandardRadiosBody}>
                        <Radio
                          label={"SwissCom"}
                          name="txtSignatureProvider"
                          value={SignatureProvider.SwisscomAis}
                          radioIcon={SwisscomLogo}
                        />
                      </div>
                      {!SigningOptionsPredefined && (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-around",
                          }}
                        >
                          <button
                            type={"button"}
                            onClick={backButtonHandler}
                            className={
                              signatureParamsCtx.predefinedSignatureOptions
                                ? styles.PreviousButtonDisabled
                                : styles.PreviousButton
                            }
                            disabled={
                              signatureParamsCtx.predefinedSignatureOptions
                            }
                          >
                            {i18next.t("back_step_label")}
                          </button>
                        </div>
                      )}
                    </Fade>
                  )}
                {formState.values["txtSignatureProvider"] !== undefined &&
                  formState.values["txtSignatureLevel"] === undefined && (
                    <Fade>
                      <div className={styles.SignatureStandardRadiosBody}>
                        <Radio
                          label={i18next.t("simple")}
                          name="txtSignatureLevel"
                          value={SignatureLevel.SES}
                          radioIcon={SESLogo}
                        />
                        <Radio
                          label={i18next.t("medium")}
                          name="txtSignatureLevel"
                          value={SignatureLevel.AES}
                          radioIcon={AESLogo}
                        />
                        <Radio
                          label={i18next.t("high")}
                          name="txtSignatureLevel"
                          value={SignatureLevel.QES}
                          radioIcon={QESLogo}
                        />
                      </div>
                      {!SigningOptionsPredefined && (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-around",
                          }}
                        >
                          <button
                            type={"button"}
                            onClick={backButtonHandler}
                            className={
                              signatureParamsCtx.predefinedSignatureOptions
                                ? styles.PreviousButtonDisabled
                                : styles.PreviousButton
                            }
                            disabled={
                              signatureParamsCtx.predefinedSignatureOptions
                            }
                          >
                            {i18next.t("back_step_label")}
                          </button>
                        </div>
                      )}
                    </Fade>
                  )}
                {formState.values["txtSignatureLevel"] !== undefined && (
                  <Fade>
                    <div className={styles.SignatureStandardRadiosBody}>
                      <Telephone name="txtMobitel" />
                      <div
                        className={
                          styles.Signator_SignatureProfileNameContainer
                        }
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        <p>{i18next.t("save_sig_profile_img_as")}</p>
                        <Input
                          style={{
                            width: "100%",
                            height: "40px",
                            marginBottom: "15px",
                          }}
                          name={"txtSigImageProfileName"}
                          placeholder="Signature profile name"
                          onChange={(e) => {
                            setProfileName(e.target.value);
                            formState.values["txtSigImageProfileName"] =
                              e.target.value;
                          }}
                          value={profileName}
                        />
                      </div>
                      {stepTwoState.acceptedTC === false && (
                        <Checkbox
                          name={"txtAcceptTC"}
                          onChange={handleTCCheckbox}
                          checked={stepTwoState.acceptTC}
                        >
                          {i18next.t("accept")}{" "}
                          <a
                            href={
                              "https://docs.google.com/document/d/e/2PACX-1vSI2Y2EleBVYUONHOm94rBDxyk2I1rXh7UUACb5Wn60i4k3uDhn4syZGkd85tgumZiXWgmtEWUnj1m_/pub?urp=gmail_link"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            {i18next.t("terms_and_conditions")}
                          </a>
                        </Checkbox>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                      }}
                      className={styles.Signator_ConfirmButtonContainer}
                    >
                      {!SigningOptionsPredefined && (
                        <button
                          type={"button"}
                          onClick={backButtonHandler}
                          className={
                            signatureParamsCtx.predefinedSignatureOptions
                              ? styles.PreviousButtonDisabled
                              : styles.PreviousButton
                          }
                          disabled={
                            signatureParamsCtx.predefinedSignatureOptions
                          }
                        >
                          {i18next.t("back_step_label")}
                        </button>
                      )}
                      <button
                        type={"submit"}
                        className={
                          formState.values["txtMobitel"] &&
                          (stepTwoState.acceptedTC === true ||
                            stepTwoState.acceptTC)
                            ? styles.SignStepButton
                            : styles.SignStepButtonDisabled
                        }
                        disabled={
                          stepTwoState.acceptedTC === false
                            ? !stepTwoState.acceptTC
                            : false
                        }
                      >
                        {i18next.t("confirm_button_label")}
                      </button>
                    </div>
                  </Fade>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fade>
  );
}

export default StepTwo;
