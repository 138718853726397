import React, { useContext, useEffect } from "react";
import { Drawer } from "antd";
import { VideoIdentDialogService } from "../VideoIdentModal/VideoIdentModal";
import { UserDevice } from "../SigningComponent/SigningComponent";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import i18next from "i18next";

const RestartVideoIdent = (props: any) => {
  const signatureParamsCtx = useContext(SignatureParametersContext);

  function listenForSigningResult(event: any) {
    if (event.data.status === "CLOSE_VIDEO_IDENT_IFRAME") {
      props.closeRestartVideoIdentDrawer();
    }
    if (event.data.status === "SIGNATOR_VIDEO_IDENT_SUCCESS_FINISHED") {
      props.closeRestartVideoIdentDrawer();
    }
  }

  window.addEventListener("message", listenForSigningResult, false);

  useEffect(() => {
    new VideoIdentDialogService(
      signatureParamsCtx.signatureToken ?? "",
      signatureParamsCtx.jurisdiction ?? "",
      signatureParamsCtx.signatureLevel ?? "",
      signatureParamsCtx.phoneNumber ?? "",
      UserDevice.DESKTOP,
      signatureParamsCtx.prepaid ?? "",
      signatureParamsCtx.targetIdentUrl ?? "",
      "false"
    ).showVideoIdentModal();
  }, []);

  return (
    <Drawer
      title={i18next.t("identification")}
      width={window.innerWidth < 1200 ? "100%" : "50%"}
      closable={true}
      onClose={props.closeRestartVideoIdentDrawer}
      placement="right"
      open={props.restartVideoIdentDrawer}
      visible={props.restartVideoIdentDrawer}
      destroyOnClose={true}
    >
      <div
        id={"signator-secondary-drawer"}
        style={{ height: "800px", maxHeight: "800px" }}
      ></div>
    </Drawer>
  );
};

export default RestartVideoIdent;
