import React, { useContext } from "react";
import { Modal } from "antd";
import styles from "../../pages/LandingPage.module.css";
import IntroTooltipContext from "../../store/IntroTooltipContext";

function IntroModal(props: any) {
  const tooltipCtx = useContext(IntroTooltipContext);

  const handleShowTooltips = () => {
    localStorage.setItem("eSignLite_ShowTooltips", "true");
    tooltipCtx.setShowTooltips(true);
    tooltipCtx.setShowIntroModal(false);
    tooltipCtx.setTooltipStep(props.initialStep);
  };

  const handleDontShowTooltips = () => {
    localStorage.setItem("eSignLite_ShowTooltips", "false");
    tooltipCtx.setShowSettingsTooltip(true);
    tooltipCtx.setShowTooltips(false);
    tooltipCtx.setShowIntroModal(false);
    tooltipCtx.setTooltipStep(props.initialStep);
  };

  return (
    <Modal
      centered
      visible={tooltipCtx.introTooltipsModalState}
      footer={null}
      className={styles.Signator_IntroModal}
      maskClosable={false}
      closable={false}
    >
      <div className={styles.Signator_IntroModalHeader}>New to eSign-LITE?</div>
      <div className={styles.Signator_IntroModalSubHeader}>
        Do you want to turn on tips navigation?
      </div>
      <div className={styles.Signator_IntroModalComponent}>
        <div
          className={styles.Signator_IntroModalButtonYes}
          onClick={handleShowTooltips}
        >
          YES
        </div>
        <div
          className={styles.Signator_IntroModalButtonNo}
          onClick={handleDontShowTooltips}
        >
          NO
        </div>
      </div>
      <div className={styles.Signator_IntroModalFooter}>
        You can always turn tooltips on or off by opening application settings.
      </div>
    </Modal>
  );
}

export default IntroModal;
