import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import IntroTooltipContext from "../../store/IntroTooltipContext";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1cbeda",
    color: "white",
    boxShadow: theme.shadows[1],
    fontFamily: "OpenSans",
    fontSize: 11,
    animation: "fadeInAnimation ease 2s",
    maxWidth: "200px",
  },
}));

const TipNavigationTooltip = (props: any) => {
  const tooltipCtx = useContext(IntroTooltipContext);

  return (
    <CustomTooltip
      arrow
      open={tooltipCtx.showSettingsTooltip}
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ textAlign: "center", padding: "2px" }}>
            <div
              id="close-navigationtooltip"
              onClick={() => tooltipCtx.setShowSettingsTooltip(false)}
            >
              <span style={{ paddingBottom: "2px" }}>x</span>
            </div>
            <div
              style={{
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "5px",
              }}
            >
              <InfoIcon style={{ fontSize: "16px", marginRight: "3px" }} />
              Tips Navigation
            </div>
            <div style={{ textAlign: "left" }}>
              If you want to review these tips later, there’s an option to turn
              them on again here.
            </div>
          </div>
        </div>
      }
      placement={"left-end"}
    >
      {props.children}
    </CustomTooltip>
  );
};

export default TipNavigationTooltip;
