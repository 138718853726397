import i18next from "i18next";
import { REDIRECT_URL } from "../../appsettings";

export enum SigningStatusDialog {
  FAILED,
  SUCCESS,
}

export class SigningStatusComponent {
  public async removeStatusModalFromDom(
    status: SigningStatusDialog,
    file?: string
  ) {
    const blobPdfFromBase64String = (base64String: string) => {
      const byteArray = Uint8Array.from(
        atob(base64String)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      return new Blob([byteArray], { type: "application/pdf" });
    };

    let messageNotSent = true;

    let statusModal = document.getElementById("status-modal");
    if (statusModal) {
      statusModal.remove();
      if (status === SigningStatusDialog.SUCCESS && file) {
        if (messageNotSent) {
          window.parent.postMessage({
            type: "SIGNATOR_SIGNEDFILE",
            file: blobPdfFromBase64String(file),
          });
        }
      } else if (status === SigningStatusDialog.SUCCESS) {
        window.location.replace(REDIRECT_URL);
      }
    }
  }

  //create and render status modal
  public renderStatusModal(
    status: SigningStatusDialog,
    errorCode?: number,
    signedFile?: undefined | string
  ) {
    //create stylesheet
    let styleSheet = document.createElement("style");
    styleSheet.innerHTML = `
    .status-modal-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 0;
      max-width: 638px;
      width: 90%;
      box-shadow: none !important;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .status-modal-content-header {
      padding: 2px 16px;
      background-color: #7b45c9;
      color: white;
      font-weight: bold;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      text-align: center;
      font-family: 'OpenSans';
    }

    .status-modal-content-body {
      font-weight: bold;
      text-align: center;
      font-family: 'OpenSans';
      align-items: center;
      font-size: medium;
      display: flex;
      justify-content: center;
      height: 200px;
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
    }

    .status-modal-content-download {
      margin-top: 25px;
      cursor: pointer;
      text-decoration: underline;
      color: #623d91;
    }

    .status-modal-content-footer {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 10px;
      height: 38px;
    }

    .modal-button {
      width: 130px;
      text-align: center;
      background-color: #623d91;
      color: white;
      font-size: 18px;
      border-radius: 5px;
      cursor: pointer;
    }
    `;
    //append CSS for modal
    document.head.appendChild(styleSheet);

    //create the background element for modal
    const modal = document.createElement("div");
    modal.id = "status-modal";

    //create modal content div element
    const modalContent = document.createElement("div");
    modalContent.classList.add("status-modal-content");

    //create modal body element
    const modalContentBody = document.createElement("div");
    modalContentBody.classList.add("status-modal-content-body");
    status === SigningStatusDialog.FAILED
      ? window.parent.postMessage({
          type: "SIGNATOR_SIGNING_ERROR",
          error: this.errorMessageDisplay(errorCode),
        })
      : window.parent.postMessage({
          type: "SIGNATOR_SIGNING_SUCCESS",
          signedFile: signedFile,
        });

    let parentElementToAppend = document.getElementById(
      "signator_drawer_container"
    );

    //render the modal element with child iframe on DOM
    modal.appendChild(modalContent);
    modalContent.appendChild(modalContentBody);
    parentElementToAppend!.appendChild(modal);
  }

  private errorMessageDisplay(errorMessageCode?: number) {
    if (errorMessageCode === 20009) {
      return i18next.t("20009_message");
    }
    if (errorMessageCode === 20007) {
      return i18next.t("20007_message");
    }
    if (errorMessageCode === 20011) {
      return i18next.t("20011_message");
    }
    if (errorMessageCode === 20005) {
      return i18next.t("20005_message");
    }
    if (errorMessageCode === 20008) {
      return i18next.t("20008_message");
    }
    if (errorMessageCode === 20010) {
      return i18next.t("20010_message");
    }
    if (errorMessageCode === 50001) {
      return i18next.t("50001_message");
    }
    if (errorMessageCode === 20016) {
      return i18next.t("20016_message");
    }
    if (errorMessageCode === undefined) {
      return i18next.t("undefined");
    } else {
      return i18next.t("unable");
    }
  }
}
