import React, { useRef, useState, useContext } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Checkbox, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import IntroTooltipContext from "../../store/IntroTooltipContext";
import TipNavigationTooltip from "../TipsNavigationTooltip/TipsNavigationTooltip";

const options = ["SETTINGS" /*"NEW SIGNATURE"*/];

const SettingsButtonMenu = (props: any) => {
  const tooltipCtx = useContext(IntroTooltipContext);

  //state variables
  const [openSignButtonDropdown, setOpenSignButtonDropdown] = useState({
    open: false,
    selectedIndex: 0,
  });
  const [settingsModalState, setSettingsModalState] = useState({
    open: false,
    imageWarning: "false",
  });

  //refs
  const signButtonDropdownAnchorRef = useRef<HTMLDivElement>(null);
  //handlers

  const handleSignMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setOpenSignButtonDropdown((prevState) => ({
      ...prevState,
      open: false,
      selectedIndex: index,
    }));

    let getWarningSetting = localStorage.getItem(
      "eSignLiteSignatureImageWarning"
    );

    setSettingsModalState((prevState) => ({
      ...prevState,
      open: true,
      imageWarning: getWarningSetting ?? "false",
    }));
  };

  const handleSignMenuToggle = () => {
    setOpenSignButtonDropdown((prevState) => ({
      ...prevState,
      open: !openSignButtonDropdown.open,
    }));

    tooltipCtx.setShowSettingsTooltip(false);
  };

  const handleSignMenuClose = (event: Event) => {
    if (
      signButtonDropdownAnchorRef.current &&
      signButtonDropdownAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenSignButtonDropdown((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleImageWarningCheckboxState = (e: CheckboxChangeEvent) => {
    setSettingsModalState((prevState) => ({
      ...prevState,
      open: true,
      imageWarning: e.target.checked.toString(),
    }));

    localStorage.setItem(
      "eSignLiteSignatureImageWarning",
      e.target.checked.toString()
    );
  };

  const handleShowTooltipsState = (e: CheckboxChangeEvent) => {
    tooltipCtx.setShowTooltips(!tooltipCtx.showTooltips);

    localStorage.setItem("eSignLite_ShowTooltips", e.target.checked.toString());
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={signButtonDropdownAnchorRef}
        aria-label="split button"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <TipNavigationTooltip>
          <Button
            size="small"
            aria-controls={
              openSignButtonDropdown.open ? "split-button-menu" : undefined
            }
            aria-expanded={openSignButtonDropdown.open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleSignMenuToggle}
            style={{
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : "white",
              color: "#623d91",
              fontSize: "12px",
              height: props.isMobile ? "30px" : "40px",
              minWidth: "28px",
              padding: "4px",
              marginLeft: props.isMobile ? "4px" : "10px",
            }}
          >
            <MoreVertIcon />
          </Button>
        </TipNavigationTooltip>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={openSignButtonDropdown.open}
        anchorEl={signButtonDropdownAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleSignMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      style={{
                        fontSize: "13px",
                        color: "#4188bb",
                        borderBottom:
                          index < options.length - 1
                            ? "1px solid #4188bb"
                            : "none",
                        fontWeight: "bold",
                        fontFamily: "OpenSans",
                      }}
                      key={option}
                      disabled={index === 1 && props.isSigAdded}
                      selected={index === openSignButtonDropdown.selectedIndex}
                      onClick={(event) => handleSignMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Modal
        visible={settingsModalState.open}
        title={"SETTINGS"}
        footer={null}
        onCancel={() =>
          setSettingsModalState((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      >
        <div>
          <div
            style={{
              fontSize: "15px",
              fontFamily: "OpenSans",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  width: "100%",
                }}
              >
                Tooltips
              </div>
              <div style={{ width: "100%", marginTop: "10px" }}>
                <Checkbox
                  onChange={handleShowTooltipsState}
                  checked={tooltipCtx.showTooltips === true ? true : false}
                >
                  Show tooltips?
                </Checkbox>
              </div>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  display: "flex",
                  width: "100%",
                }}
              >
                Signature image settings
              </div>
              <div style={{ width: "100%", marginTop: "10px" }}>
                <Checkbox
                  onChange={handleImageWarningCheckboxState}
                  checked={
                    settingsModalState.imageWarning === "true" ? true : false
                  }
                >
                  Don't warn me if I haven't placed signature image to the
                  document.
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SettingsButtonMenu;
