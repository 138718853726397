export const toUint8Array = async (file: File) => {
  let tempFile = file as Blob;
  let convertedFile = await tempFile.arrayBuffer();
  return new Uint8Array(convertedFile);
};

export function splitBase64(signatureImage: string) {
  let splitData = (signatureImage as string)?.split(";base64,");
  if (splitData?.length === 2) {
    return splitData[1];
  } else {
    return splitData[0];
  }
}

export const base64_arraybuffer = async (data: any) => {
  const base64url = (await new Promise((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result);
    reader.readAsDataURL(new Blob([data]));
  })) as any;

  return base64url.split(",", 2)[1];
};

export const base64_arraybuffer_no_split = async (data: any) => {
  const base64url = (await new Promise((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result);
    reader.readAsDataURL(new Blob([data]));
  })) as any;

  return base64url;
};

export function base64toBlob(base64Data: string, contentType: string) {
  contentType = contentType || "";
  let sliceSize = 1024;
  let byteCharacters = atob(base64Data);
  let bytesLength = byteCharacters.length;
  let slicesCount = Math.ceil(bytesLength / sliceSize);
  let byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let begin = sliceIndex * sliceSize;
    let end = Math.min(begin + sliceSize, bytesLength);

    let bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const blobPdfFromBase64String = (base64String: string) => {
  const byteArray = Uint8Array.from(
    atob(base64String)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  return new Blob([byteArray], { type: "application/pdf" });
};
