import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "react-final-form";
import { Drawer } from "antd";
import { Fade } from "react-awesome-reveal";
import { DEFAULT_SIGNING_MODEL } from "../../services/signingModel";
import StepTwo from "./SigningWizardPages/StepTwo";
import StepThree from "./SigningWizardPages/StepThree";
import StepperComponent from "./StepperComponent";
import { v4 as uuid } from "uuid";
import { ErrorModalService } from "../../components/ErrorHandling/ErrorModalService";
import axios from "axios";
import IntroTooltipContext from "../../store/IntroTooltipContext";
import i18next from "i18next";
import {
  base64_arraybuffer,
  base64_arraybuffer_no_split,
} from "../../services/utilityServices";
import StepOne from "./SigningWizardPages/StepOne";
import { BASE_URL } from "../../appsettings";

interface IWizardState {
  currentPage: number;
}
const initialState: IWizardState = {
  currentPage: 0,
};

const SigningWizard = (props: any) => {
  const tooltipCtx = useContext(IntroTooltipContext);

  const [state, setState] = useState<IWizardState>(initialState);
  const pages = useMemo(() => [StepOne, StepTwo, StepThree], []);
  const [addProfileState, setAddProfileState] = useState(false);
  const [requiredFieldNotEmpty, setRequiredFieldNotEmpty] = useState(true);
  const [waitForSignatureImageRes, setWaitForSignatureImageRes] =
    useState(false);

  const [widthForDrawer, setWidthForDrawer] = useState<number>(
    window.innerWidth
  );

  const unique_id = uuid();

  //getting info of window width
  function handleWindowSizeChange() {
    setWidthForDrawer(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      currentPage: props.startingStep ?? 0,
    }));
    setAddProfileState(false);
    setRequiredFieldNotEmpty(true);
  }, [props.signingDrawerOpen]);

  const nextSlide = useCallback(() => {
    if (state.currentPage < pages.length) {
      setState((prevState) => ({
        ...prevState,
        currentPage: Math.min(state.currentPage + 1, pages.length),
      }));
    }
  }, [state, pages]);

  const nextWithImage = useCallback(
    (signatureImage: any) => {
      if (signatureImage === undefined) {
        setRequiredFieldNotEmpty(false);
        return;
      } else {
        setAddProfileState(true);
        tooltipCtx.setTooltipStep(3);
        nextSlide();
      }
    },
    [state, pages]
  );

  const skipHandler = useCallback(() => {
    if (state.currentPage < pages.length) {
      setState((prevState) => ({
        ...prevState,
        currentPage: Math.min(state.currentPage + 1, pages.length),
      }));
    }
    props.removeSignatureHandler();
  }, [state, pages]);

  const previousSlide = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      currentPage: Math.max(state.currentPage - 1, 0),
    }));
  }, [state, pages]);

  //this submit function handles saving profile, and opening third page of drawer where signing is being executed
  const onSubmit = useCallback(
    async (values) => {
      //setting T&C accepted values to storage
      if (values.txtAcceptTC === true) {
        localStorage.setItem("eSignLiteTCAccepted", "true");
      }

      //checking if required fields are empty and returning value
      if (state.currentPage === 0) {
        if (values.txtName === undefined) {
          setRequiredFieldNotEmpty(false);
        }
        return;
      }

      if (state.currentPage === pages.length - 1) {
        return;
      } else if (state.currentPage === 1) {
        let checkLocalStorageProfiles = localStorage.getObj(
          "eSignLiteSignatures"
        ) as Array<any>;

        let checkLocalStorageProfilesNoImage = localStorage.getObj(
          "eSignLiteSignaturesOnly"
        ) as Array<any>;

        //saving signature profiles
        if (values.txtSignatureImagePicture) {
          if (
            checkLocalStorageProfiles === null ||
            checkLocalStorageProfiles === undefined
          ) {
            try {
              localStorage.setObj("eSignLiteSignatures", [
                {
                  ...values,
                  txtType: values.txtSignatureImagePicture
                    ? "IMAGE"
                    : "NO_IMAGE",
                  default: false,
                  profileId: unique_id,
                },
              ]);
            } catch (e) {
              new ErrorModalService(29).showErrorModal();
              return;
            }
            if (props.signingWithSignatureImage) {
              setWaitForSignatureImageRes(true);
              try {
                const createSigImage = await axios.post(
                  BASE_URL + "/v/1/signer/pdf/signature/image/preview",
                  {
                    Page: 0,
                    WidgetOffsetX: 231,
                    WidgetOffsetY: 359,
                    WidgetHeight: 75,
                    WidgetWidth: 150,
                    ImageData: values.txtSignatureImagePicture,
                    LineOne: values.txtName,
                    LineTwo: values.txtPosition,
                    LineThree: values.txtDepartment,
                    LineFour: values.txtCompanyName,
                    LogoImageData: null,
                    Appearance: "CARD",
                  },
                  { responseType: "arraybuffer" }
                );

                let createdSignatureImage = await base64_arraybuffer_no_split(
                  createSigImage.data
                );

                setWaitForSignatureImageRes(false);

                props.signWithCreatedSignatureImageHandler(
                  values,
                  createdSignatureImage
                );
              } catch (e) {
                new ErrorModalService(30).showErrorModal();
                setWaitForSignatureImageRes(false);
                console.log(e);
              }
            }
          } else {
            checkLocalStorageProfiles.push({
              ...values,
              txtType: values.txtSignatureImagePicture ? "IMAGE" : "NO_IMAGE",
              default: false,
              profileId: unique_id,
            });
            try {
              localStorage.setObj(
                "eSignLiteSignatures",
                checkLocalStorageProfiles
              );
            } catch (e) {
              new ErrorModalService(29).showErrorModal();
              return;
            }

            if (props.signingWithSignatureImage) {
              setWaitForSignatureImageRes(true);
              try {
                const createSigImage = await axios.post(
                  BASE_URL + "/v/1/signer/pdf/signature/image/preview",
                  {
                    Page: 0,
                    WidgetOffsetX: 231,
                    WidgetOffsetY: 359,
                    WidgetHeight: 75,
                    WidgetWidth: 150,
                    ImageData: values.txtSignatureImagePicture,
                    LineOne: values.txtName,
                    LineTwo: values.txtPosition,
                    LineThree: values.txtDepartment,
                    LineFour: values.txtCompanyName,
                    LogoImageData: null,
                    Appearance: "CARD",
                  },
                  { responseType: "arraybuffer" }
                );

                let createdSignatureImage = await base64_arraybuffer_no_split(
                  createSigImage.data
                );

                setWaitForSignatureImageRes(false);

                props.signWithCreatedSignatureImageHandler(
                  values,
                  createdSignatureImage
                );
              } catch (e) {
                new ErrorModalService(30).showErrorModal();
                setWaitForSignatureImageRes(false);
                console.log(e);
              }
            }
          }
        }

        if (!values.txtSignatureImagePicture) {
          if (
            checkLocalStorageProfilesNoImage === null ||
            checkLocalStorageProfilesNoImage === undefined
          ) {
            try {
              localStorage.setObj("eSignLiteSignaturesOnly", [
                {
                  ...values,
                  txtType: values.txtSignatureImagePicture
                    ? "IMAGE"
                    : "NO_IMAGE",
                  default: false,
                  profileId: unique_id,
                },
              ]);
            } catch (e) {
              new ErrorModalService(29).showErrorModal();
              return;
            }

            nextSlide();
          } else {
            checkLocalStorageProfilesNoImage.push({
              ...values,
              txtType: values.txtSignatureImagePicture ? "IMAGE" : "NO_IMAGE",
              default: false,
              profileId: unique_id,
            });
            try {
              localStorage.setObj(
                "eSignLiteSignaturesOnly",
                checkLocalStorageProfilesNoImage
              );
            } catch (e) {
              new ErrorModalService(29).showErrorModal();
              return;
            }

            nextSlide();
          }
        }
      } else {
        nextSlide();
      }
    },
    [state, pages.length, nextSlide]
  );

  const signWithSavedProfile = () => {
    setState((prevState) => ({
      ...prevState,
      currentPage: 2,
    }));
  };

  const onDrawerClose = () => {
    window.postMessage({ status: "SIGNATOR_DRAWER_CLOSED" });
    if (state.currentPage !== 2) {
      let resetFormButton = document.getElementById(
        "signator-reset-form-button"
      ) as HTMLButtonElement;

      setState((prevState) => ({
        ...prevState,
        currentPage: 0,
      }));
      resetFormButton.click();
    }
    props.closeSigningDrawer();
  };

  const CurrentPage = pages[state.currentPage];
  return (
    <Drawer
      title={i18next.t("define_sig_header")}
      placement="right"
      onClose={onDrawerClose}
      visible={props.signingDrawerOpen}
      destroyOnClose={true}
      maskClosable={false}
      width={
        widthForDrawer < 1450 ? (widthForDrawer < 700 ? "95%" : "50%") : "33%"
      }
      push={{
        distance:
          widthForDrawer < 1200 ? (widthForDrawer < 700 ? 65 : 250) : 450,
      }}
    >
      <Fade>
        <StepperComponent currentPage={state.currentPage} />
        <Form onSubmit={onSubmit} initialValues={DEFAULT_SIGNING_MODEL}>
          {({ handleSubmit, form }) => {
            return (
              <form id="main-form" onSubmit={handleSubmit}>
                <CurrentPage
                  numPages={pages.length}
                  currentPage={state.currentPage}
                  closeDrawer={props.closeSigningDrawer}
                  removeSignatureHandler={props.removeSignatureHandler}
                  signingWithSignatureImage={props.signingWithSignatureImage}
                  requiredFieldNotEmpty={requiredFieldNotEmpty}
                  onNext={nextSlide}
                  nextWithImage={nextWithImage}
                  onPrevious={previousSlide}
                  pagesLength={pages.length}
                  skipHandler={skipHandler}
                  signatureImage={props.signatureImage}
                  signWithCreatedSignatureImageHandler={
                    props.signWithCreatedSignatureImageHandler
                  }
                  waitingForSignatureImage={waitForSignatureImageRes}
                  signWithImageContinue={props.signWithImageContinue ?? false}
                  signingImageProfile={props.signingImageProfile}
                  signWithSavedProfile={signWithSavedProfile}
                  addProfileStateHandler={setAddProfileState}
                  addProfileState={addProfileState}
                  widthParam={widthForDrawer}
                />
                <button
                  hidden={true}
                  type={"button"}
                  id={"signator-reset-form-button"}
                  onClick={(e: any) => {
                    e.preventDefault();
                    form.reset();
                  }}
                />
              </form>
            );
          }}
        </Form>
      </Fade>
    </Drawer>
  );
};

export default SigningWizard;
